<template>
  <section class="privacy_page">
    <h1>{{ currentData?.title || 'Legal Versions' }}</h1>
    <h2>{{ $t('legal.key007') }}</h2>
    <div v-if="currentData && currentData.files.length">
      <div v-for="(file, index) in currentData.files" :key="index" class="file_btn">
        <i class="bi bi-download"></i>
        <a :href="file.url" target="_blank">
          {{ file.fileName }}
        </a>
      </div>
    </div>
    <div v-else>
      <p>No data available for the selected legal version.</p>
    </div>
  </section>
</template>
<script>
export default {
  name: 'LegalPage',
  components: {},
  data() {
    return {
      legalData: {
        privacy: {
          en: {
            title: this.$t('privacy.key001'),
            files: [
              {
                url: `${this.$cloudFront}/Legal/PrivacyPolicy/en/PrivacyPolicy_BEATDAY_ENG_211227.pdf`,
                fileName: 'PrivacyPolicy_BEATDAY_211227.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/PrivacyPolicy/en/PrivacyPolicy_BEATDAY_ENG_221017.pdf`,
                fileName: 'PrivacyPolicy_BEATDAY_221017.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/PrivacyPolicy/en/PrivacyPolicy_BEATDAY_ENG_230209.pdf`,
                fileName: 'PrivacyPolicy_BEATDAY_230209.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/PrivacyPolicy/en/PrivacyPolicy_BEATDAY_ENG_240126.pdf`,
                fileName: 'PrivacyPolicy_BEATDAY_240126.pdf',
              },
            ],
          },
          zh_tw: {
            title: this.$t('privacy.key001'),
            files: [
              {
                url: `${this.$cloudFront}/Legal/PrivacyPolicy/zh-tw/PrivacyPolicy_BEATDAY_CHT_211227.pdf`,
                fileName: 'PrivacyPolicy_BEATDAY_211227.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/PrivacyPolicy/zh-tw/PrivacyPolicy_BEATDAY_CHT_221017.pdf`,
                fileName: 'PrivacyPolicy_BEATDAY_221017.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/PrivacyPolicy/zh-tw/PrivacyPolicy_BEATDAY_CHT_230209.pdf`,
                fileName: 'PrivacyPolicy_BEATDAY_230209.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/PrivacyPolicy/zh-tw/PrivacyPolicy_BEATDAY_CHT_240126.pdf`,
                fileName: 'PrivacyPolicy_BEATDAY_240126.pdf',
              },
            ],
          },
        },
        'user-policy': {
          en: {
            title: this.$t('user-policy.key001'),
            files: [
              {
                url: `${this.$cloudFront}/Legal/UserPolicy/en/UserPolicy_BEATDAY_ENG_211227.pdf`,
                fileName: 'UserPolicy_BEATDAY_211227.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/UserPolicy/en/UserPolicy_BEATDAY_ENG_221017.pdf`,
                fileName: 'UserPolicy_BEATDAY_221017.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/UserPolicy/en/UserPolicy_BEATDAY_ENG_230209.pdf`,
                fileName: 'UserPolicy_BEATDAY_230209.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/UserPolicy/en/UserPolicy_BEATDAY_ENG_231122.pdf`,
                fileName: 'UserPolicy_BEATDAY_231122.pdf',
              },
            ],
          },
          zh_tw: {
            title: this.$t('user-policy.key001'),
            files: [
              {
                url: `${this.$cloudFront}/Legal/UserPolicy/zh-tw/UserPolicy_BEATDAY_CHT_211227.pdf`,
                fileName: 'UserPolicy_BEATDAY_211227.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/UserPolicy/zh-tw/UserPolicy_BEATDAY_CHT_221017.pdf`,
                fileName: 'UserPolicy_BEATDAY_221017.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/UserPolicy/zh-tw/UserPolicy_BEATDAY_CHT_230209.pdf`,
                fileName: 'UserPolicy_BEATDAY_230209.pdf',
              },
              {
                url: `${this.$cloudFront}/Legal/UserPolicy/zh-tw/UserPolicy_BEATDAY_CHT_231122.pdf`,
                fileName: 'UserPolicy_BEATDAY_231122.pdf',
              },
            ],
          },
        },
      },
      currentData: null,
    };
  },
  watch: {
    '$route.query.legal': {
      immediate: true,
      handler(newVal) {
        this.updateCurrentData(newVal);
      },
    },
    currentLanguage: {
      immediate: true,
      handler() {
        this.updateCurrentData(this.$route.query.legal);
      },
    },
  },
  methods: {
    updateCurrentData(legalKey) {
      const language = this.currentLanguage === 'zh_tw' ? 'zh_tw' : 'en';
      const languageData = this.legalData[legalKey]?.[language];
      this.currentData = languageData || null;
    },
  },
  mounted() {
    this.updateCurrentData(this.$route.query.legal);
  },
};
</script>

<style lang="scss"></style>
