<template>
  <section class="serial-number">
    <h1>{{ $t('serial-number.key001') }}</h1>
    <div class="flex_con">
      <div class="left-section">
        <h2>{{ $t('serial-number.key002') }}</h2>
        <input type="text" v-model="serialNumber" class="serial-number-input" :placeholder="$t('serial-number.key002')" />
        <button class="submit-button" @click="verify()">
          {{ $t('serial-number.key003') }}
        </button>
        <div class="alert">{{ alert }}</div>
      </div>

      <div class="right-section">
        <h2>{{ $t('serial-number.key004') }}</h2>
        <ul>
          <li>{{ $t('serial-number.key007') }}</li>
          <li>{{ $t('serial-number.key008') }}</li>
          <li v-html="$t('serial-number.key009')"></li>
        </ul>
      </div>
    </div>
  </section>
  <Loading v-show="loading"></Loading>
  <binding-popup v-if="showBindingPopup" @close-alert="hideAlert"></binding-popup>
</template>

<script>
import BindingPopup from '@/components/memberCenter/BindingPopup.vue';
import { redeemTicket } from '@/api/apiService';
import Loading from '@/components/Loading.vue';
export default {
  name: 'SerialNumber',
  mounted() {
    if (this.jwt == null || this.loginMethod == null) {
      this.$router.push(`/${this.currentLanguage}/member/member-information`);
    }
  },
  computed: {
    jwt() {
      return this.$store.state.jwt;
    },
    loginMethod() {
      return this.$store.state.loginMethod;
    },
  },
  data() {
    return {
      serialNumber: '',
      alert: '',
      tickets: {},
      showBindingPopup: false,
      loading: false,
    };
  },
  components: {
    'binding-popup': BindingPopup,
    Loading,
  },
  methods: {
    async verify() {
      try {
        if (!this.serialNumber) {
          this.alert = this.$t('serial-number.key027');
          return;
        }

        // if not basic login
        if (this.loginMethod !== 1 && this.serialNumber.length === 8 && this.serialNumber.startsWith('BO')) {
          this.showBindingPopup = true;
        } else {
          await this.redeem();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async redeem() {
      try {
        this.loading = true;
        const response = await redeemTicket(this.serialNumber, this.jwt);
        this.loading = false;
        const responseData = response.data.data;
        if (responseData.StatusCode === 3) {
          //TODO: fix latter
          this.alert = '';
          const confirmation = window.confirm(this.$t('serial-number.key005'));
          if (confirmation) {
            this.$router.push(`/${this.currentLanguage}/member/ticket-section`);
          }
        } else if (responseData.StatusCode === 1) {
          this.alert = 'Code has been redeemed';
        } else {
          this.alert = this.$t('serial-number.key028');
        }
      } catch (error) {
        this.alert = this.$t('serial-number.key028');
        console.error(error);
      }
    },
    showAlert() {
      this.showBindingPopup = true;
    },
    hideAlert() {
      this.showBindingPopup = false;
    },
    playAnimation() {
      this.$nextTick(() => {
        const checkBox = document.querySelector('#check-box');
        if (checkBox) {
          setInterval(() => {
            checkBox.play();
          }, 0);
        }
      });
    },
    reload() {
      console.log('aaaaa'); // 不能刪
      location.reload();
    },
  },
};
</script>
