<template>
  <section class="member-information account">
    <h1>{{ $t('member-information.key001') }}</h1>

    <div class="member-info">
      <div v-show="!editing">
        <div class="reward-progress">
          <h3>{{ $t('member-information.key075') }}</h3>
          <p>{{ $t('member-information.key076') }}</p>
          <div class="reward">
            <div class="line" :style="{ '--line-width': lineWidth }">
              <div
                v-for="(item, index) in gifts"
                :key="index"
                class="dot"
                :class="{
                  close: userData.quantityFilled < item.standard,
                }"
              ></div>
            </div>
            <div class="content">
              <div
                class="text"
                :class="{
                  finish: index + 1 <= userData.collectionStage,
                }"
                :data-content="$t('member-information.key087')"
                v-for="(item, index) in gifts"
                :key="index"
              >
                <p class="textStep">{{ item.stepText }}</p>
                <img
                  src="../../assets/img/public/sc_15.png"
                  alt=""
                  :class="{
                    close: index + 1 <= userData.collectionStage || userData.quantityFilled < item.standard,
                  }"
                  @click="sendGift(index + 1, item.standard)"
                />
                <p class="item_name">{{ item.itemName }}</p>
                <p>x {{ item.quantity }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="member-list">
          <span class="title">{{ $t('member-information.key002') }}</span>
          <div class="avatar">
            <img :src="userData.avatar" @error="avatarError('avatar')" alt="User Avatar" />
          </div>
        </div>
        <div class="member-list">
          <span class="title">{{ $t('member-information.key003') }}</span>
          <p v-if="!!userData.name">
            {{ userData.name }}
          </p>
          <p class="empty" v-else>
            {{ $t('member-information.key018') }}
          </p>
        </div>
        <div class="member-list">
          <span class="title">{{ $t('member-information.key004') }}</span>
          <p v-if="!!userData.nickname">
            {{ userData.nickname }}
          </p>
          <p class="empty" v-else>
            {{ $t('member-information.key018') }}
          </p>
        </div>
        <div class="member-list mobile_flex_column">
          <span class="title">{{ $t('member-information.key005') }}</span>
          <p :class="{ empty: userData.uid === '未填寫' }">
            {{ userData.uid }}
          </p>
          <div class="copy" @click="copyToClipboard(userData.uid)">
            <img src="../../assets/img/member/copy.svg" alt="" />
          </div>
        </div>
        <div class="member-list mobile_flex_column">
          <span class="title">{{ $t('member-information.key006') }}</span>
          <div class="icons">
            <div
              v-for="method in loginMethods"
              :key="method.id"
              :class="[
                'icon',
                {
                  active: method.id === userData.currentLoginMethod,
                },
              ]"
            >
              <img :src="method.icon" :alt="`Login Method ${method.id}`" />
            </div>
            <span></span>
          </div>
        </div>
        <div class="member-list mobile_flex_column">
          <span class="title">{{ $t('member-information.key007') }}</span>
          <p>{{ userData.account }}</p>
          <div class="copy" @click="copyToClipboard(userData.account)">
            <img src="../../assets/img/member/copy.svg" alt="" />
          </div>
        </div>
        <div class="member-list mobile_flex_column">
          <span class="title">{{ $t('member-information.key008') }}</span>
          <p v-if="!!userData.contactEmail">
            {{ userData.contactEmail }}
          </p>
          <p class="empty" v-else>
            {{ $t('member-information.key018') }}
          </p>
        </div>
        <div class="member-list">
          <span class="title">{{ $t('member-information.key009') }}</span>
          <p v-if="userData.gender != 0">
            {{ genderLabel[userData.gender] || '' }}
          </p>
          <p class="empty" v-else>
            {{ $t('member-information.key018') }}
          </p>
        </div>
        <div class="member-list mobile_flex_column">
          <span class="title">{{ $t('member-information.key010') }}</span>
          <p v-if="!!userData.phone">
            {{ userData.phone }}
          </p>
          <p class="empty" v-else>
            {{ $t('member-information.key018') }}
          </p>
        </div>
        <div class="member-list">
          <span class="title">{{ $t('member-information.key011') }}</span>
          <p v-if="!!userData.birth">
            {{ userData.birth }}
          </p>
          <p class="empty" v-else>
            {{ $t('member-information.key018') }}
          </p>
        </div>
        <div class="member-list">
          <span class="title">{{ $t('member-information.key012') }}</span>
          <p v-if="!!userData.area">
            {{ userAreaText }}
          </p>
          <p class="empty" v-else>
            {{ $t('member-information.key018') }}
          </p>
        </div>
        <div class="member-list">
          <span class="title">{{ $t('member-information.key013') }}</span>
          <p v-if="!!userData.city">
            {{ userCityText }}
          </p>
          <p class="empty" v-else>
            {{ $t('member-information.key018') }}
          </p>
        </div>
        <div class="form-like member-list title_flex_top mobile_flex_column">
          <span class="title">{{ $t('member-information.key014') }}</span>
          <div class="address">
            <label class="addressDetail">
              <p v-if="!!userData.FullAddress">
                {{ userData.FullAddress }}
              </p>
              <p class="empty" v-else>
                {{ $t('member-information.key018') }}
              </p>
            </label>
          </div>
        </div>
        <div class="member-list">
          <span class="title">{{ $t('member-information.key015') }}</span>
          <ul v-if="userData.interests.length != 0" class="hobby">
            <li v-for="(interest, index) in userData.interests" :key="index">
              {{ interestLabel(interest) }}
            </li>
          </ul>
          <p v-else class="empty">
            {{ $t('member-information.key018') }}
          </p>
        </div>
        <div class="member-list mobile_flex_column">
          <span class="title">{{ $t('member-information.key016') }}</span>
          <input type="checkbox" v-model="userData.agreeMail" disabled />
          <label class="agree_mail">
            <span style="padding-left: 30px">
              {{ $t('member-information.key017') }}
            </span>
          </label>
        </div>
        <div class="down">
          <button @click="deleteAccount" class="submit-button button_outline">
            {{ $t('member-information.key086') }}
          </button>
          <button @click="startEditing" class="submit-button">
            {{ $t('member-information.key019') }}
          </button>
        </div>
      </div>
      <form v-show="editing" @submit.prevent="updateUserInfo">
        <div class="member-list edit">
          <span class="title">{{ $t('member-information.key002') }}</span>
          <div class="avatar_flex">
            <div class="avatar" @click="triggerFilePicker">
              <img :src="previewAvatar" @error="avatarError('previewAvatar')" alt="User Avatar" />
            </div>
            <input ref="fileInput" type="file" accept=".png, .jpg, .jpeg" @change="handleFileChange" style="display: none" />
            <div class="avatar_limit">
              <p>
                {{ $t('member-information.key020') }}
                <br />
                {{ $t('member-information.key021') }}
              </p>
              <p class="remove" @click="removeAvatar">
                {{ $t('member-information.key022') }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="showCropper" class="cropper-modal">
          <div class="mask"></div>
          <div class="cropper">
            <cropper :src="cropperImage" :stencil-props="{ aspectRatio: 1 / 1 }" @change="updateCroppedImage" :auto-zoom="true" />
            <div class="btn_box">
              <button type="button" class="button_outline" @click="cancelCrop">
                {{ $t('member-information.key023') }}
              </button>
              <button type="button" class="button_style_linear" @click="confirmCrop">
                {{ $t('member-information.key024') }}
              </button>
            </div>
          </div>
        </div>
        <ImageUploader ref="imageUploader" @upload-success="handleUploadSuccess" @upload-error="handleUploadError" />

        <div class="member-list edit title_flex_top">
          <label>
            <p class="title note">
              <span>*</span>
              {{ $t('member-information.key003') }}
            </p>
          </label>
          <div class="input_box">
            <input v-model="userData.name" @input="nameInput" @blur="nameChecking" />
            <div class="alert">{{ nameAlert }}</div>
          </div>
        </div>
        <div class="member-list edit">
          <label>
            <span class="title">{{ $t('member-information.key004') }}</span>
          </label>
          <div class="input_box">
            <input v-model="userData.nickname" @input="nicknameInput" @blur="nicknameChecking" />
            <div class="alert">{{ nicknameAlert }}</div>
          </div>
        </div>
        <div class="member-list edit">
          <label>
            <span class="title">{{ $t('member-information.key005') }}</span>
          </label>
          <input v-model="userData.uid" disabled />
        </div>
        <div class="member-list edit">
          <span class="title">{{ $t('member-information.key006') }}</span>
          <div class="icons">
            <div
              v-for="method in loginMethods"
              :key="method.id"
              :class="[
                'icon',
                {
                  active: method.id === userData.currentLoginMethod,
                },
              ]"
            >
              <img :src="method.icon" :alt="`Login Method ${method.id}`" />
            </div>
          </div>
        </div>
        <div class="member-list edit">
          <label>
            <span class="title">{{ $t('member-information.key007') }}</span>
          </label>
          <input v-model="userData.account" disabled />
        </div>
        <label class="member-list edit mail title_flex_top">
          <p class="title note">
            <span>*</span>
            {{ $t('member-information.key008') }}
          </p>
          <div class="contactEmail">
            <input v-if="contactEmailEditing" v-model="tempContactEmail" :disabled="verifyShow" />
            <input v-else v-model="userData.contactEmail" disabled />
            <div class="alert">{{ contactEmailAlert }}</div>
            <div class="verify_email" v-if="!contactEmailEditing || verifyShow">
              <span v-if="verifyShow">{{ $t('member-information.key025') }}</span>
              <span class="change_email" @click="startEditEmail">
                {{ $t('member-information.key026') }}
              </span>
            </div>
            <div class="verifySuccess verify_email" v-if="verifySuccess">
              <p>
                <i class="bi bi-check-circle-fill"></i>
                &nbsp;{{ $t('member-information.key027') }}
              </p>
            </div>
            <button type="button" v-if="contactEmailEditing && !verifyShow" @click="sendVerifyCode" class="submit-button">
              {{ $t('member-information.key028') }}
            </button>
          </div>
        </label>
        <div class="member-list edit title_flex_top" v-if="verifyShow">
          <label>
            <span class="title">{{ $t('member-information.key029') }}</span>
          </label>
          <div class="contactEmail">
            <input v-model="verificationCode" />
            <div class="verify_email">
              <p class="resend" :class="{ disResend: !resendShow }" @click="resendCode">
                <i class="bi bi-arrow-clockwise"></i>
                <span class="sendText">{{ $t('member-information.key030') }}</span>
                <span v-if="!resendShow">&nbsp;{{ countdown }}s</span>
              </p>
              <span class="verify_time">
                {{ $t('member-information.key031') }}
                {{ formatTime(timer) }}
              </span>
            </div>
            <div class="alert">{{ alertVerify }}</div>
          </div>
        </div>
        <label class="member-list edit">
          <span class="title">{{ $t('member-information.key009') }}</span>
          <div class="genderRadio">
            <input type="radio" id="male" v-model="userData.gender" :value="genderMap.male" />
            <label for="male">{{ $t('member-information.key032') }}</label>

            <input type="radio" id="female" v-model="userData.gender" :value="genderMap.female" />
            <label for="female">{{ $t('member-information.key033') }}</label>

            <input type="radio" id="reserve" v-model="userData.gender" :value="genderMap.reserve" />
            <label for="reserve">{{ $t('member-information.key034') }}</label>
          </div>
        </label>
        <div class="member-list edit phone_box">
          <span class="title">{{ $t('member-information.key010') }}</span>
          <div class="input_box">
            <vue-tel-input
              v-model="userData.phone"
              :dropdownOptions="dropdownOptions"
              :inputOptions="inputOptions"
              @validate="phonValidate"
              mode="international"
              @blur="PhoneBlur"
            />
            <div class="alert">{{ phoneAlert }}</div>
          </div>
        </div>
        <label class="member-list edit">
          <span class="title">{{ $t('member-information.key011') }}</span>
          <div class="input_box">
            <input type="date" v-model="userData.birth" class="birth" @change="birthChecking" @keydown.enter.prevent />
            <div class="alert">{{ birthdayAlert }}</div>
          </div>
        </label>

        <div class="member-list edit">
          <span class="title">{{ $t('member-information.key012') }}</span>
          <div class="select_arrow">
            <country-select v-model="userData.area" :country="userData.area" topCountry="TW" :usei18n="false" :placeholder="$t('member-information.key035')" />
          </div>
        </div>
        <div class="member-list edit">
          <span class="title">{{ $t('member-information.key013') }}</span>
          <label v-if="userData.area === 'TW'">
            <div class="select_box">
              <select v-model="userData.city">
                <option value="" disabled selected hidden>
                  {{ $t('member-information.key035') }}
                </option>
                <option v-for="(TWCityKey, index) in Object.keys(TWCityMap)" :key="index" :value="TWCityKey">
                  {{ TWCityMap[TWCityKey] }}
                </option>
              </select>
            </div>
          </label>
          <div class="select_arrow" v-else>
            <region-select v-model="userData.city" :region="userData.city" :country="userData.area" :usei18n="false" :placeholder="$t('member-information.key035')" />
          </div>
        </div>
        <div class="member-list edit title_flex_top">
          <span class="title">{{ $t('member-information.key014') }}</span>
          <div class="address">
            <div id="zipcode2"></div>
            <label class="addressDetail">
              <input v-model="userData.address.addressDetail" :placeholder="$t('member-information.key064')" />
            </label>
          </div>
        </div>
        <div class="member-list edit title_flex_top">
          <span class="title">{{ $t('member-information.key015') }}</span>
          <div class="input_box">
            <div class="interest-buttons">
              <label v-for="(interest, index) in Object.keys(interestMap)" :key="index">
                <input type="checkbox" :value="interest" v-model="userData.interests" @change="changeInterests" />
                <span :class="{ selected: isSelected(interest) }">
                  {{ interestLabel(interest) }}
                </span>
              </label>
            </div>
            <div class="alert">{{ interestAlert }}</div>
          </div>
        </div>
        <div class="member-list edit">
          <span class="title">{{ $t('member-information.key016') }}</span>
          <input type="checkbox" id="agree_mail" v-model="userData.agreeMail" />
          <label for="agree_mail" class="agree_mail">
            <span style="padding-left: 30px">
              {{ $t('member-information.key017') }}
            </span>
          </label>
        </div>
        <div class="down" v-if="!registerEditShow">
          <button type="button" @click="finishEditing" class="submit-button button_outline">
            {{ $t('member-information.key023') }}
          </button>
          <button type="submit" class="submit-button">
            {{ $t('member-information.key053') }}
          </button>
        </div>
        <div class="privacy" v-if="registerEditShow && this.userData.currentLoginMethod !== 1">
          <p v-html="formattedMessage"></p>
          <button type="submit" class="submit-button">
            {{ $t('register.key014') }}
          </button>
        </div>
        <div class="down" v-if="registerEditShow">
          <button type="submit" class="submit-button">
            {{ $t('serial-number.registration-binding_key010') }}
          </button>
        </div>
      </form>
    </div>
    <div class="copySuccess">{{ $t('member-information.key054') }}</div>
  </section>

  <Loading v-show="loading"></Loading>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import { ref } from 'vue';
import { Cropper } from 'vue-advanced-cropper';
import Loading from '@/components/Loading.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import forbiddenWords from '@/assets/data/forbiddenWords.json';
import { allCountries } from 'country-region-data';
import 'vue-tel-input/vue-tel-input.css';
import 'vue-advanced-cropper/dist/style.css';
import {
  getPresignedUrl,
  removeAvatar as removeAvatarApi,
  removeAvatarCache,
  getUserInfo,
  updateUserInfo as updateUserInfoApi,
  getCodeToVerifyEmail,
  updateEmail,
  sendGift,
  deleteUser,
} from '@/api/apiService';
import { HttpStatusCode } from 'axios';

const DEFAULT_AVATAR = 'https://via.placeholder.com/64x64?text=Slide01';

export default {
  name: 'MemberInformation',
  components: {
    Cropper,
    VueTelInput,
    Loading,
    ImageUploader,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    registerEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    /** @type {import('vue').Ref<InstanceType<typeof ImageUploader> | null>} */
    const imageUploader = ref(null);
    return {
      imageUploader,
    };
  },
  data() {
    return {
      //LoginMethods-icon
      loginMethods: [
        {
          id: 1,
          icon: require('@/assets/img/member/loginMethod_beatday.svg'),
        },
        {
          id: 2,
          icon: require('@/assets/img/member/loginMethod_google.svg'),
        },
        {
          id: 3,
          icon: require('@/assets/img/member/loginMethod_facebook.svg'),
        },
        {
          id: 4,
          icon: require('@/assets/img/member/loginMethod_apple.svg'),
        },
        {
          id: 5,
          icon: require('@/assets/img/member/loginMethod_discord.svg'),
        },
      ],

      registerEditShow: this.registerEdit,

      //Phone-mpn
      dropdownOptions: {
        disabled: false,
        showDialCodeInList: true,
        showDialCodeInSelection: false,
        showFlags: true,
        width: '100%',
      },
      inputOptions: {
        placeholder: this.$t('member-information.key065'),
        showDialCode: true,
      },

      //ImageCut
      tempFile: null,
      showCropper: false,
      croppedImage: null,
      maxFileSize: 5 * 1024 * 1024,
      previewAvatar: DEFAULT_AVATAR,
      cropperImage: null,
      tempAvatar: null,

      //Model
      loading: false,
      editing: this.edit,

      //Check
      disallowedCharsRegex: /[''<>\\;=()\s]/, // 禁止的特殊字元
      nameCheck: false,
      nicknameCheck: false,
      phoneIsValid: null,

      //Alert
      nameAlert: '',
      nicknameAlert: '',
      contactEmailAlert: '',
      phoneAlert: '',
      birthdayAlert: '',
      interestAlert: '',

      //nameData
      forbiddenWords: forbiddenWords,

      //mailData
      contactEmailEditing: false,
      tempContactEmail: '',
      verifyShow: false,
      resendShow: false,
      verificationCode: '',
      alertVerify: '',
      verifySuccess: false,
      countdownJump: 5,
      timer: 0,

      //UserData
      userData: {
        avatar: DEFAULT_AVATAR,
        name: '',
        nickname: '',
        uid: '',
        currentLoginMethod: 0,
        account: '',
        contactEmail: '',
        gender: 0,
        phone: '',
        birth: '1990-02-01', //1990-01-01 格式
        area: '',
        city: '',
        address: {
          county: '',
          zipCode: '',
          district: '',
          addressDetail: '',
        },
        FullAddress: '',
        interests: [],
        agreeMail: false,
        collectionStage: 0,
        quantityFilled: 0,
      },
      giftNamesMap: {
        IPR9001: this.$t('member-information.key080'),
      },
      gifts: [
        {
          stepText: this.$t('member-information.key077'),
          itemName: this.$t('member-information.key080'),
          quantity: 2,
          standard: 3,
        },
        {
          stepText: this.$t('member-information.key078'),
          itemName: this.$t('member-information.key080'),
          quantity: 3,
          standard: 6,
        },
        {
          stepText: this.$t('member-information.key079'),
          itemName: this.$t('member-information.key080'),
          quantity: 5,
          standard: 9,
        },
      ],
      genderMap: {
        male: 1,
        female: 2,
        reserve: 3,
      },
      genderLabel: {
        1: this.$t('member-information.key032'),
        2: this.$t('member-information.key033'),
        3: this.$t('member-information.key034'),
      },
      interestMap: {
        interest_key001: this.$t('member-information.key055'),
        interest_key002: this.$t('member-information.key056'),
        interest_key003: this.$t('member-information.key057'),
        interest_key004: this.$t('member-information.key058'),
        interest_key005: this.$t('member-information.key059'),
        interest_key006: this.$t('member-information.key060'),
        interest_key007: this.$t('member-information.key061'),
        interest_key008: this.$t('member-information.key062'),
        interest_key009: this.$t('member-information.key063'),
      },
      TWCityMap: {
        TPE: this.$t('member-information.key036'),
        NWT: this.$t('member-information.key037'),
        KEE: this.$t('member-information.key038'),
        TAO: this.$t('member-information.key040'),
        HSZ: this.$t('member-information.key039'),
        TXG: this.$t('member-information.key040-1'),
        TNN: this.$t('member-information.key044'),
        KHH: this.$t('member-information.key043'),
        YUN: this.$t('member-information.key042'),
        HSQ: this.$t('member-information.key041'),
        CYI: this.$t('member-information.key045'),
        CYQ: this.$t('member-information.key046'),
        CWH: this.$t('member-information.key046-1'),
        CWS: this.$t('member-information.key046-2'),
        PIF: this.$t('member-information.key047'),
        PEN: this.$t('member-information.key048'),
        HUA: this.$t('member-information.key049'),
        TTT: this.$t('member-information.key050'),
        KIN: this.$t('member-information.key051'),
        LIE: this.$t('member-information.key052'),
      },
    };
  },
  computed: {
    jwt() {
      return this.$store.state.jwt;
    },
    formattedMessage() {
      if (this.userData.currentLoginMethod !== 1) {
        return this.$t('register.key012', {
          userPolicyLink: `<a href='#/${this.currentLanguage}/legal/user-policy' target="_blank">${this.$t('register.key025')}</a>`,
          privacyPolicyLink: `<a href='#/${this.currentLanguage}/legal/privacy' target="_blank">${this.$t('register.key026')}</a>`,
          serviceTermsLink: `<a href='#/${this.currentLanguage}/legal/privacy' target="_blank">${this.$t('register.key027')}</a>`,
        });
      }
      return '';
    },
    lineWidth() {
      const filled = this.userData.quantityFilled;
      const stage = this.userData.collectionStage;
      let filledPercentage = 0;
      let stagePercentage = 0;
      if (filled <= 2) filledPercentage = 5;
      else if (filled === 3) filledPercentage = 13;
      else if (filled <= 5) filledPercentage = 29;
      else if (filled === 6) filledPercentage = 45;
      else if (filled <= 8) filledPercentage = 61;
      else if (filled === 9) filledPercentage = 78;

      switch (stage) {
        case 1:
          stagePercentage = 13;
          break;
        case 2:
          stagePercentage = 45;
          break;
        case 3:
          stagePercentage = 78;
          break;
        default:
          stagePercentage = 0;
          break;
      }

      return stagePercentage >= filledPercentage ? `${stagePercentage}%` : `${filledPercentage}%`;
    },
    userArea() {
      return this.userData.area;
    },
    userAreaOfAllCountries() {
      if (this.userData.area) {
        const country = allCountries.find((country) => country[1] === this.userData.area);

        if (country) {
          return country;
        }
      }

      return null;
    },
    userAreaText() {
      return this.userAreaOfAllCountries?.[0] || '';
    },
    userCityText() {
      if (this.userData.area === 'TW') {
        return this.TWCityMap[this.userData.city] || '';
      } else {
        if (this.userAreaOfAllCountries && this.userData.city) {
          const region = this.userAreaOfAllCountries[2].find((region) => region[1] === this.userData.city);

          return region?.[0] || '';
        }
      }

      return '';
    },
  },
  mounted() {
    this.initTwzipcode();
    this.getUserInfo();
  },
  beforeUnmount() {
    this.stopCountdown();
  },
  watch: {
    verificationCode(newVal) {
      if (newVal.length === 6) {
        this.updateEmail();
      }
    },
    userArea(newValue, oldValue) {
      if (oldValue !== '') {
        this.userData.city = '';
      }
    },
  },
  methods: {
    async deleteAccount() {
      const userConfirmed = window.confirm(this.$t('member-information.key083'));
      if (userConfirmed) {
        this.loading = true;
        try {
          await deleteUser(this.jwt);
          alert(this.$t('member-information.key084'));
          this.$router.push(`/${this.currentLanguage}`);
          this.$store.dispatch('logout');
        } catch (error) {
          console.error(this.$t('member-information.key085'), error);
        } finally {
          this.loading = false;
        }
      }
    },
    async getUserInfo() {
      try {
        this.loading = true;
        const userData = await getUserInfo(this.jwt, true);
        if (!userData) return;

        this.userData.uid = userData.uid;
        this.userData.name = userData.name || '';
        this.userData.nickname = userData.nickname || '';
        this.userData.currentLoginMethod = userData.currentLoginMethod || 0;
        this.userData.phone = userData.phone || '';
        this.userData.gender = userData.gender || 0;
        this.userData.birth = userData.birth || '';
        this.userData.area = userData.area || '';
        this.userData.city = userData.city || '';
        this.userData.contactEmail = userData.contactEmail || '';
        this.userData.agreeMail = userData.agreeMail || false;
        this.userData.account = userData.account || '';
        this.userData.avatar = userData.avatar || DEFAULT_AVATAR;
        this.userData.interests = userData.interests || [];
        this.userData.address.county = userData.address.county || '';
        this.userData.address.zipCode = userData.address.zipCode || '';
        this.userData.address.district = userData.address.district || '';
        this.userData.address.addressDetail = userData.address.addressDetail || '';

        this.userData.collectionStage = userData.collectionStage || 0;
        this.userData.quantityFilled = userData.quantityFilled || 0;
        this.previewAvatar = this.userData.avatar;
        this.contactEmailEditing = !this.userData.contactEmail;

        $('#zipcode2').twzipcode('set', {
          county: this.userData.address.county,
          district: this.userData.address.district,
          zipcode: this.userData.address.zipCode,
        });

        this.userData.FullAddress = `${this.userData.address.zipCode}${this.userData.address.county}${this.userData.address.district}${this.userData.address.addressDetail}`;
      } catch (error) {
        if (error?.response?.status === 400) {
          // todo: redirect?
          alert('Failed to get user info');
          console.error('account not found');
        } else {
          console.error('error', error.message);
        }
      } finally {
        this.loading = false;
      }
    },
    async updateUserInfo() {
      let updateSuccess = false;
      try {
        this.loading = true;

        this.nameChecking();
        this.nicknameChecking();
        // this.birthChecking();
        this.checkContactEmail();

        if (
          !(
            (this.nameCheck && this.nicknameCheck && this.userData.contactEmail)
            // !this.birthdayAlert
          )
        ) {
          alert('Please check your filled fields.');
          return;
        }

        this.userData.phone = this.phoneIsValid ? this.userData.phone : '';

        const data = {
          info: {
            name: this.userData.name,
            phone: this.userData.phone.replace(/\s+/g, ''),
            gender: this.userData.gender,
            birth: this.userData.birth,
            area: this.userData.area,
            city: this.userData.city,
            address: {
              county: this.userData.address.county,
              zipCode: this.userData.address.zipCode,
              district: this.userData.address.district,
              addressDetail: this.userData.address.addressDetail,
            },
            interests: this.userData.interests,
            nickname: this.userData.nickname,
            agreeMail: this.userData.agreeMail,
          },
        };

        await updateUserInfoApi(data, this.jwt);
        updateSuccess = true;

        if (this.registerEditShow) {
          this.$router.push(`/${this.currentLanguage}/member/member-information`);
        } else {
          this.$router.go(0);
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          alert('Update user info failed');
        } else {
          console.error('Error updating user info:', error);
        }
      } finally {
        // if updateSuccess, wait page redirect
        if (!updateSuccess) {
          this.loading = false;
        }
      }
    },
    async getCodeToVerifyEmail() {
      this.loading = true;
      const data = {
        contactEmail: this.tempContactEmail,
      };

      try {
        await getCodeToVerifyEmail(data, this.jwt);

        this.stopCountdown();
        this.resetCountdown();
        this.startCountdown();

        this.alertVerify = '';
        this.verifyShow = true;
      } catch (error) {
        if ([HttpStatusCode.MethodNotAllowed, HttpStatusCode.BadRequest].includes(error?.response?.status)) {
          alert('Fail to get verify code');
        } else {
          console.error('Error getCodeToVerifyEmail:', error);
        }
      } finally {
        this.loading = false;
      }
    },
    async updateEmail() {
      try {
        this.loading = true;
        this.stopCountdown();

        const data = {
          contactEmail: this.tempContactEmail,
          code: this.verificationCode,
        };
        await updateEmail(data, this.jwt);

        this.verifySuccess = true;
        this.contactEmailEditing = false;
        this.verifyShow = false;
        this.userData.contactEmail = this.tempContactEmail;
        this.contactEmailAlert = '';
        this.tempContactEmail = '';
        this.verificationCode = '';
      } catch (error) {
        if (error?.response?.status === HttpStatusCode.BadRequest) {
          this.alertVerify = this.$t('register.key029');
        } else {
          console.error('Error updateEmail:', error);
        }

        this.startCountdown();
      } finally {
        this.loading = false;
      }
    },
    interestLabel(interest) {
      return this.interestMap[interest] || '無';
    },
    //avatar
    triggerFilePicker() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      if (['image/png', 'image/jpeg'].includes(file.type) && file.size <= this.maxFileSize) {
        this.tempFile = file;
        this.cropperImage = URL.createObjectURL(file);
        this.showCropper = true;
      } else {
        alert(this.$t('member-information.key066'));
      }
    },
    updateCroppedImage({ canvas }) {
      canvas.toBlob((blob) => {
        this.croppedImage = blob;
      });
    },
    async confirmCrop() {
      try {
        this.loading = true;

        if (this.croppedImage) {
          const compressedImage = await this.compressImage(this.croppedImage, 64, 64);
          this.previewAvatar = URL.createObjectURL(compressedImage);
          this.tempAvatar = compressedImage; // Store the blob for later use
        }
        await this.uploadAvatar();

        this.closeCropper();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    cancelCrop() {
      this.closeCropper();
    },
    closeCropper() {
      this.showCropper = false;
      this.cropperImage = null;
      this.croppedImage = null;
      this.tempFile = null;

      this.$refs.fileInput.value = '';
    },
    async removeAvatar() {
      try {
        this.loading = true;
        const fileType = 'png';
        await removeAvatarApi(fileType, this.jwt);

        this.previewAvatar = DEFAULT_AVATAR;
        this.userData.avatar = DEFAULT_AVATAR;
        this.$refs.fileInput.value = ''; // Reset file input
      } catch (error) {
        alert('Remove avatar failed.');
      } finally {
        this.loading = false;
      }
    },
    compressImage(image, width, height) {
      return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;

        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob((blob) => {
            resolve(blob);
          }, 'image/png'); // Save as PNG
        };
        img.src = URL.createObjectURL(image);
      });
    },
    async uploadAvatar() {
      try {
        // only allow png file
        const fileType = 'png';
        const response = await getPresignedUrl(fileType, this.jwt);

        const { data: presignedUrl } = response.data;
        const file = this.tempAvatar;
        await this.imageUploader.uploadS3Image(file, presignedUrl);

        // clear cache
        await removeAvatarCache(fileType, this.jwt);
      } catch (error) {
        // todo: error message handler
        alert('Failed to get upload avatar');
        console.error(error);
      }
    },
    handleUploadSuccess() {
      // todo: message handler
      alert('Upload avatar success!');
    },
    handleUploadError(error) {
      // todo: error message handler
      alert(error);
    },
    //NameCheck
    nameInput() {
      this.userData.name = this.userData.name.trim();
      this.nameAlert = '';
    },
    nameChecking() {
      const name = this.userData.name;

      if (!(name && name.length >= 2)) {
        this.nameAlert = this.$t('member-information.key069');
        this.nameCheck = false;
        return;
      }
      if (name.length >= 20) {
        this.nameAlert = this.$t('member-information.key067');
        this.nameCheck = false;
        return;
      }
      if (!this.isWordingValid(name) || this.disallowedCharsRegex.test(name)) {
        this.nameAlert = this.$t('member-information.key068');
        this.nameCheck = false;
        return;
      }

      this.nameAlert = '';
      this.nameCheck = true;
    },
    // nicknameCheck
    nicknameInput() {
      this.userData.nickname = this.userData.nickname.trim();
      this.nicknameAlert = '';
    },
    nicknameChecking() {
      const nickname = this.userData.nickname;

      if (nickname.length >= 20) {
        this.nicknameAlert = this.$t('member-information.key067');
        this.nicknameCheck = false;
        return;
      }
      if (!this.isWordingValid(nickname) || this.disallowedCharsRegex.test(nickname)) {
        this.nicknameAlert = this.$t('member-information.key068');
        this.nicknameCheck = false;
        return;
      }

      this.nicknameAlert = '';
      this.nicknameCheck = true;
    },
    // 檢查禁字
    isWordingValid(value) {
      for (let word of this.forbiddenWords) {
        if (value.includes(word)) {
          return false;
        }
      }

      return true;
    },
    //更改信箱
    startEditEmail() {
      this.contactEmailAlert = '';
      this.verifySuccess = false;
      this.tempContactEmail = '';
      this.contactEmailEditing = true;
      this.verifyShow = false;
    },
    //驗證碼期限_format
    formatTime(seconds) {
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = seconds % 60;
      if (h > 0) {
        return `${h}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
      } else {
        return `${m}:${s.toString().padStart(2, '0')}`;
      }
    },
    //重設倒數時間 > 驗證碼發送 & 驗證碼期限
    resetCountdown() {
      this.countdown = 60;
      this.timer = 600;
    },
    //停止倒數
    stopCountdown() {
      clearInterval(this.interval);
      clearInterval(this.timerInterval);
    },
    //驗證碼發送_倒數
    startCountdown() {
      this.interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.interval);
          this.resendShow = true;
        }
      }, 1000);

      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.timerInterval);
          this.alertVerify = this.$t('member-information.key070');
        }
      }, 1000);
    },
    checkContactEmail() {
      if (!this.userData.contactEmail) {
        this.contactEmailAlert = this.$t('member-information.key071');
        return;
      } else {
        this.contactEmailAlert = '';
      }
    },
    //發送驗證碼
    sendVerifyCode() {
      // 1. 檢查帳號格式
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.tempContactEmail)) {
        this.contactEmailAlert = this.$t('member-information.key071');
        return;
      }

      this.contactEmailAlert = '';
      this.getCodeToVerifyEmail();
    },
    //重新發送驗證碼
    resendCode() {
      this.getCodeToVerifyEmail();
      this.resendShow = false;
    },
    //phone
    phonValidate(object) {
      if (object.valid === true) {
        this.phoneIsValid = true;
      } else {
        this.phoneIsValid = false;
      }
    },
    PhoneBlur() {
      if (!this.phoneIsValid) {
        this.phoneAlert = this.$t('member-information.key072');
        return false;
      } else {
        this.phoneAlert = '';
      }
    },
    //birthdayCheck
    birthChecking() {
      if (!this.userData.birth) {
        this.birthdayAlert = '';
        return;
      }
      const today = new Date().toISOString().split('T')[0];
      if (this.userData.birth > today) {
        this.birthdayAlert = this.$t('member-information.key073');
      } else {
        this.birthdayAlert = '';
      }
    },
    //interests
    isSelected(interest) {
      return this.userData.interests?.includes(interest);
    },
    changeInterests() {
      if (this.userData.interests.length > 3) {
        this.interestAlert = this.$t('member-information.key074');
        this.userData.interests.pop();
      } else {
        this.interestAlert = '';
      }
    },
    //address
    initTwzipcode() {
      const self = this;
      $('#zipcode2').twzipcode({
        countySel: '',
        districtSel: '',
        zipcodeIntoDistrict: true,
        css: ['city form-control', 'town form-control'],
        countyName: 'city',
        districtName: 'town',
        onCountySelect: function () {
          self.updateAddress();
        },
        onDistrictSelect: function () {
          self.updateAddress();
        },
      });

      $('#zipcode2-readonly').twzipcode({
        readonly: true,
        countySel: '',
        districtSel: '',
        zipcodeIntoDistrict: true,
        css: ['city form-control', 'town form-control'],
        countyName: 'city',
        districtName: 'town',
      });

      $('#zipcode2-readonly')
        .find('select')
        .each(function () {
          $(this).prop('disabled', true);
        });
    },
    updateAddress() {
      const addressInfo = $('#zipcode2').twzipcode('get');

      this.userData.address.county = addressInfo.county.val();
      this.userData.address.district = addressInfo.district.val();
      this.userData.address.zipCode = addressInfo.zipcode.val();
    },
    avatarError(field) {
      switch (field) {
        case 'avatar':
          this.userData.avatar = DEFAULT_AVATAR;
          break;
        case 'previewAvatar':
          this.previewAvatar = DEFAULT_AVATAR;
          break;
        default:
          break;
      }
    },
    startEditing() {
      this.editing = true;
    },
    finishEditing() {
      // 取消編輯，重新整理頁面
      location.reload();
    },

    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          $('.copySuccess').css('animation-name', 'copySuccess');
          setTimeout(() => {
            $('.copySuccess').css('animation-name', '');
          }, 2000);
        })
        .catch((err) => {
          console.error(this.$t('my-orders.key023'), err);
        });
    },
    async sendGift(collectionStage, standard) {
      const validStatus = collectionStage > this.userData.collectionStage && this.userData.quantityFilled >= standard;
      const data = {
        collectionStage: collectionStage, // the collection stage clicked by user
      };
      try {
        if (!validStatus) return;
        this.loading = true;
        const response = await sendGift(data, this.jwt);
        const responseData = response.data.data;
        this.userData.collectionStage = responseData.collectionStage; // updated collection stage
        const rewards = responseData.added_gifts.map((gift) => `${this.giftNamesMap[gift.gift_id] || gift.gift_id} x ${gift.quantity}`);
        this.userData.collectionStage = collectionStage;
        const alertMessage = this.$t('member-information.key082') + rewards.join('\n');
        alert(alertMessage);
        this.$store.commit('setTempUserInfo', null);
      } catch (error) {
        if (error?.response?.status === HttpStatusCode.Forbidden) {
          alert('quantity filled not enough, does not match the collectionStage');
          console.error('quantity filled not enough, does not match the collectionStage');
        } else if (error?.response?.status === HttpStatusCode.BadRequest) {
          console.error('incoming stage is not bigger than current stage');
        } else {
          console.error('Error sendGift:', error);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
