<template>
  <div class="desktop-header">
    <div class="left_flex">
      <router-link :to="`/${currentLanguage}`">
        <div class="BEATDAY-logo">
          <img src="@/assets/img/headerFooter/BEATDAY_white.svg" alt="BEATDAY-Logo" />
        </div>
      </router-link>
      <nav class="menu">
        <ul class="mainMenu">
          <li v-for="(menuItem, index) in menuItems" :key="index" class="mainMenuList">
            <router-link :to="`/${currentLanguage}/${menuItem.route}`" class="mainLink">
              {{ menuItem.label }}
            </router-link>

            <ul v-if="menuItem.submenu" class="submenu">
              <li v-for="(subItem, subIndex) in menuItem.submenu" :key="subIndex" class="subMenuList">
                <router-link :to="`/${currentLanguage}/${subItem.route}`" class="subLink">
                  <p>{{ subItem.label }}</p>
                  <div class="submenu_icon"></div>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <div class="right_flex">
      <div class="login" v-if="!loginSuccess">
        <router-link :to="`/${currentLanguage}/member-login/login`" class="button">
          {{ $t('common.header_key003') }}
        </router-link>
      </div>
      <div class="login-success login" v-if="loginSuccess">
        <router-link :to="`/${currentLanguage}/member`" class="button menu">
          <i class="bi bi-person-fill"></i>
          <span>{{ nickName }}</span>
          <ul class="submenu">
            <li v-for="(subItem, index) in memberMenu[0].submenu" :key="index" class="subMenuList">
              <router-link :to="`/${currentLanguage}/${subItem.route}`" class="subLink">
                <p>{{ subItem.label }}</p>
                <div class="submenu_icon"></div>
              </router-link>
            </li>
          </ul>
        </router-link>

        <div @click="logOut" class="logOut">
          {{ $t('common.header_key004') }}
        </div>
      </div>
      <router-link :to="`/${currentLanguage}/cart`" class="shopping-cart">
        <i class="bi bi-cart3"></i>
        <div class="cart_count" v-if="cartCount">
          <p>{{ cartCount }}</p>
        </div>
      </router-link>
    </div>
    <div class="social-links">
      <div class="divider"></div>
      <div class="follow-us">Follow Us</div>
      <a href="https://line.me/R/ti/p/@485ibcgx?oat__id=29053" target="_blank" class="line">
        <img src="@/assets/img/headerFooter/Line.svg" alt="" />
      </a>
      <a href="https://www.facebook.com/beatday.music" target="_blank" class="FB">
        <img src="@/assets/img/headerFooter/facebook.svg" alt="" />
      </a>
      <a href="https://www.instagram.com/beatday.music/" target="_blank" class="instagram">
        <img src="@/assets/img/headerFooter/Instagram.svg" alt="" />
      </a>
      <a href="https://twitter.com/beatday_verse" target="_blank" class="twitter">
        <img src="@/assets/img/headerFooter/twitter.svg" alt="" />
      </a>
      <a href="https://www.youtube.com/channel/UCtZsfDkAPwPqOzlENXSjFPA" target="_blank" class="youtube">
        <img src="@/assets/img/headerFooter/youtube.svg" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    memberMenu: {
      type: Array,
      required: true,
    },
    cartCount: {
      type: Number,
      required: null,
    },
    jwt: {
      type: String,
      required: null,
    },
    nickName: {
      type: String,
      default: 'nickName',
    },
    loginSuccess: {
      type: Boolean,
      required: true,
    },
    handleClick: {
      type: Function,
      required: true,
    },
    logOut: {
      type: Function,
      required: true,
    },
  },
  methods: {},
};
</script>
