<template>
  <aside>
    <h1>{{ $t('common.member_nav_key007') }}</h1>
    <nav>
      <ul>
        <li>
          <router-link :to="`/${currentLanguage}/member/member-information`" active-class="active">
            {{ $t('common.member_nav_key004') }}
          </router-link>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/member/reset-password`" active-class="active">
            {{ $t('common.member_nav_key005') }}
          </router-link>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/member/ticket-section`" exact active-class="active">
            {{ $t('common.member_nav_key001') }}
          </router-link>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/member/serial-number/serial-number-default`" active-class="active">
            {{ $t('common.member_nav_key002') }}
          </router-link>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/member/my-orders`" :class="{ active: orderPathIsActive }">
            {{ $t('my-orders.key001') }}
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NavAside',
  computed: {
    ...mapState(['loginMethod']),
    orderPathIsActive() {
      return this.$route.path.startsWith(`/${this.currentLanguage}/member/my-orders`) || this.$route.path.startsWith(`/${this.currentLanguage}/member/order-details`);
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss"></style>
