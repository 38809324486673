<template>
  <app-header></app-header>
  <div id="app">
    <div class="wrapper section member">
      <nav-aside></nav-aside>
      <router-view></router-view>
    </div>
  </div>
  <app-footer></app-footer>
</template>
<script>
import NavAside from '@/components/memberCenter/Nav.vue';
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';

export default {
  name: 'MemberPage',
  components: {
    'nav-aside': NavAside,
    'app-header': AppHeader,
    'app-footer': AppFooter,
  },
  data() {
    return {
      test: 1,
    };
  },
  props: {},
  computed: {},
  methods: {},
  watch: {},
};
</script>

<style lang="scss">
@import '../assets/styles/pages/memberCenter/member.scss';
@import '../assets/styles/pages/memberCenter/ticketSection.scss';
@import '../assets/styles/pages/memberCenter/myProps.scss';
@import '../assets/styles/pages/memberCenter/serialNumber.scss';
@import '../assets/styles/pages/memberCenter/memberInformation.scss';
@import '../assets/styles/pages/memberCenter/accountSetting.scss';
@import '../assets/styles/pages/memberCenter/myOrders.scss';
</style>
