<template>
  <section class="privacy_page returnPolicy-page">
    <h1>{{ $t('sales-and-return-policy.key001') }}</h1>
    <p>{{ $t('sales-and-return-policy.key002') }}</p>
    <p v-html="formattedMessage_key003"></p>

    <ol>
      <li>
        {{ $t('sales-and-return-policy.key004') }}
        <ol>
          <li>
            {{ $t('sales-and-return-policy.key005') }}
            <span v-html="formattedMessage_key006"></span>
          </li>
          <li>
            {{ $t('sales-and-return-policy.key007') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key008') }}</li>
              <li>{{ $t('sales-and-return-policy.key009') }}</li>
              <li>{{ $t('sales-and-return-policy.key010') }}</li>
              <li>{{ $t('sales-and-return-policy.key011') }}</li>
              <li>
                {{ $t('sales-and-return-policy.key012') }}
                <ul>
                  <li>{{ $t('sales-and-return-policy.key013') }}</li>
                  <li>{{ $t('sales-and-return-policy.key014') }}</li>
                  <li>{{ $t('sales-and-return-policy.key015') }}</li>
                </ul>
              </li>
              <li>{{ $t('sales-and-return-policy.key016') }}</li>
              <li>{{ $t('sales-and-return-policy.key017') }}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        {{ $t('sales-and-return-policy.key018') }}
        <ol>
          <li>{{ $t('sales-and-return-policy.key019') }}</li>
          <li>{{ $t('sales-and-return-policy.key020') }}</li>
          <li>{{ $t('sales-and-return-policy.key021') }}</li>
          <li>{{ $t('sales-and-return-policy.key022') }}</li>
          <li>{{ $t('sales-and-return-policy.key023') }}</li>
          <li>{{ $t('sales-and-return-policy.key024') }}</li>
          <li>{{ $t('sales-and-return-policy.key025') }}</li>
        </ol>
      </li>
      <li>
        {{ $t('sales-and-return-policy.key026') }}
        <ol>
          <li>{{ $t('sales-and-return-policy.key027') }}</li>
          <li>{{ $t('sales-and-return-policy.key028') }}</li>
          <li>{{ $t('sales-and-return-policy.key029') }}</li>
          <li>{{ $t('sales-and-return-policy.key030') }}</li>
          <li>{{ $t('sales-and-return-policy.key031') }}</li>
          <li>{{ $t('sales-and-return-policy.key032') }}</li>
          <li>{{ $t('sales-and-return-policy.key033') }}</li>
        </ol>
      </li>
      <li>
        {{ $t('sales-and-return-policy.key034') }}
        <ol>
          <li>{{ $t('sales-and-return-policy.key035') }}</li>
          <li>{{ $t('sales-and-return-policy.key036') }}</li>
          <li>{{ $t('sales-and-return-policy.key037') }}</li>
          <li>{{ $t('sales-and-return-policy.key038') }}</li>
          <li>{{ $t('sales-and-return-policy.key039') }}</li>
        </ol>
      </li>
      <li>
        {{ $t('sales-and-return-policy.key040') }}
        <ol>
          <li>
            {{ $t('sales-and-return-policy.key041') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key042') }}</li>
              <li>{{ $t('sales-and-return-policy.key043') }}</li>
              <li>{{ $t('sales-and-return-policy.key044') }}</li>
            </ol>
          </li>
          <li>
            {{ $t('sales-and-return-policy.key045') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key046') }}</li>
              <li>{{ $t('sales-and-return-policy.key047') }}</li>
              <li>{{ $t('sales-and-return-policy.key048') }}</li>
            </ol>
          </li>
          <li>
            {{ $t('sales-and-return-policy.key049') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key050') }}</li>
              <li>{{ $t('sales-and-return-policy.key051') }}</li>
              <li>{{ $t('sales-and-return-policy.key052') }}</li>
            </ol>
          </li>
          <li>
            {{ $t('sales-and-return-policy.key053') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key054') }}</li>
              <ol>
                <li>{{ $t('sales-and-return-policy.key055') }}</li>
                <li>{{ $t('sales-and-return-policy.key056') }}</li>
                <li>{{ $t('sales-and-return-policy.key057') }}</li>
              </ol>
              <li>{{ $t('sales-and-return-policy.key058') }}</li>
              <li>{{ $t('sales-and-return-policy.key059') }}</li>
            </ol>
          </li>
          <li>{{ $t('sales-and-return-policy.key060') }}</li>
          <li>
            {{ $t('sales-and-return-policy.key061') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key062') }}</li>
              <li>{{ $t('sales-and-return-policy.key063') }}</li>
              <li>
                {{ $t('sales-and-return-policy.key064') }}
                <ol>
                  <li>{{ $t('sales-and-return-policy.key065') }}</li>
                  <li>{{ $t('sales-and-return-policy.key066') }}</li>
                  <li>{{ $t('sales-and-return-policy.key067') }}</li>
                  <li>{{ $t('sales-and-return-policy.key068') }}</li>
                  <li>{{ $t('sales-and-return-policy.key069') }}</li>
                  <li>{{ $t('sales-and-return-policy.key070') }}</li>
                  <li>{{ $t('sales-and-return-policy.key071') }}</li>
                  <li>{{ $t('sales-and-return-policy.key072') }}</li>
                </ol>
              </li>
              <li>{{ $t('sales-and-return-policy.key073') }}</li>
              <li v-html="formattedMessage_key074"></li>
              <li>{{ $t('sales-and-return-policy.key075') }}</li>
              <li>FB:<a href="https://www.facebook.com/beatday.music">https://www.facebook.com/beatday.music</a></li>
              <li>instagram:<a href="https://www.instagram.com/beatday.music/">https://www.instagram.com/beatday.music/</a></li>
              <li>X:<a href="https://x.com/beatday_verse">https://x.com/beatday_verse</a></li>
              <li>{{ $t('sales-and-return-policy.key079') }}</li>
              <li>{{ $t('sales-and-return-policy.key080') }}</li>
            </ol>
          </li>
        </ol>
      </li>
      <!-- list -->
      <li>
        {{ $t('sales-and-return-policy.key081') }}
        <ol>
          <li>
            {{ $t('sales-and-return-policy.key082') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key083') }}</li>
              <li>{{ $t('sales-and-return-policy.key084') }}</li>
              <li>{{ $t('sales-and-return-policy.key085') }}</li>
            </ol>
          </li>
          <li>
            {{ $t('sales-and-return-policy.key086') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key087') }}</li>
              <li>{{ $t('sales-and-return-policy.key088') }}</li>
              <li>{{ $t('sales-and-return-policy.key089') }}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        {{ $t('sales-and-return-policy.key090') }}
        <ol>
          <li>{{ $t('sales-and-return-policy.key091') }}</li>
          <li>{{ $t('sales-and-return-policy.key092') }}</li>
          <li>{{ $t('sales-and-return-policy.key093') }}</li>
          <li>{{ $t('sales-and-return-policy.key094') }}</li>
          <li>{{ $t('sales-and-return-policy.key095') }}</li>
        </ol>
      </li>
      <li>
        {{ $t('sales-and-return-policy.key096') }}
        <ol>
          <li>
            {{ $t('sales-and-return-policy.key097') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key098') }}</li>
              <li>{{ $t('sales-and-return-policy.key099') }}</li>
              <li>{{ $t('sales-and-return-policy.key100') }}</li>
              <li>{{ $t('sales-and-return-policy.key101') }}</li>
            </ol>
            {{ $t('sales-and-return-policy.key102') }}
          </li>
          <li>
            {{ $t('sales-and-return-policy.key103') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key104') }}</li>
              <li>{{ $t('sales-and-return-policy.key105') }}</li>
              <li>{{ $t('sales-and-return-policy.key106') }}</li>
            </ol>
          </li>
          <li>{{ $t('sales-and-return-policy.key107') }}</li>
        </ol>
      </li>
      <li>
        {{ $t('sales-and-return-policy.key108') }}
      </li>
      <li>
        {{ $t('sales-and-return-policy.key109') }}
      </li>
      <li>
        {{ $t('sales-and-return-policy.key110') }}
      </li>
      <li>
        {{ $t('sales-and-return-policy.key111') }}
      </li>
      <li>
        {{ $t('sales-and-return-policy.key112') }}
        <ol>
          <li>{{ $t('sales-and-return-policy.key113') }}</li>
          <li>{{ $t('sales-and-return-policy.key114') }}</li>
        </ol>
      </li>
      <li>
        {{ $t('sales-and-return-policy.key115') }}
        <ol>
          <li>
            {{ $t('sales-and-return-policy.key116') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key117') }}</li>
              <li>{{ $t('sales-and-return-policy.key118') }}</li>
              <li>{{ $t('sales-and-return-policy.key119') }}</li>
            </ol>
          </li>
          <li>
            {{ $t('sales-and-return-policy.key120') }}
            <ol>
              <li>{{ $t('sales-and-return-policy.key121') }}</li>
              <li>{{ $t('sales-and-return-policy.key122') }}</li>
              <li>
                {{ $t('sales-and-return-policy.key123') }}
                <ol>
                  <li>{{ $t('sales-and-return-policy.key124') }}</li>
                  <li>{{ $t('sales-and-return-policy.key125') }}</li>
                  <li>{{ $t('sales-and-return-policy.key126') }}</li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        {{ $t('sales-and-return-policy.key127') }}
        <ol>
          <li>{{ $t('sales-and-return-policy.key128') }}</li>
          <li>{{ $t('sales-and-return-policy.key129') }}</li>
        </ol>
      </li>
      <li>
        {{ $t('sales-and-return-policy.key130') }}
        <ol>
          <li>{{ $t('sales-and-return-policy.key131') }}</li>
          <li>{{ $t('sales-and-return-policy.key132') }}</li>
        </ol>
      </li>
      <li>
        {{ $t('sales-and-return-policy.key133') }}
        <ol>
          <li>{{ $t('sales-and-return-policy.key134') }}</li>
          <li>{{ $t('sales-and-return-policy.key135') }}</li>
          <li>{{ $t('sales-and-return-policy.key136') }}</li>
          <li>{{ $t('sales-and-return-policy.key137') }}</li>
          <li>{{ $t('sales-and-return-policy.key138') }}</li>
          <li>{{ $t('sales-and-return-policy.key139') }}</li>
          <li>{{ $t('sales-and-return-policy.key140') }}</li>
        </ol>
      </li>
    </ol>
  </section>
</template>
<script>
export default {
  name: 'LegalPage',
  components: {},
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {
    formattedMessage_key003() {
      return this.$t('sales-and-return-policy.key003', {
        userPolicyLink: `<a href="#/${this.currentLanguage}/legal/user-policy" target="_blank">${this.$t('register.key025')}</a>`,
        privacyPolicyLink: `<a href="#/${this.currentLanguage}/legal/privacy" target="_blank">${this.$t('register.key026')}</a>`,
      });
    },
    formattedMessage_key006() {
      return this.$t('sales-and-return-policy.key006', {
        userPolicyLink: `<a href="#/${this.currentLanguage}/legal/user-policy" target="_blank">${this.$t('register.key025')}</a>`,
        privacyPolicyLink: `<a href="#/${this.currentLanguage}/legal/privacy" target="_blank">${this.$t('register.key026')}</a>`,
      });
    },
    formattedMessage_key074() {
      return this.$t('sales-and-return-policy.key074', {
        Line: '<a href="https://line.me/R/ti/p/@485ibcgx?oat__id=29053" target="_blank">Line@</a',
      });
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.returnPolicy-page {
  a {
    color: #e79fff;
  }
  ol > ol {
    list-style-type: none;
    padding-left: 20px;
  }
  li {
    list-style-type: none;
  }
  ul > li {
    list-style-type: circle;
  }
}
</style>
