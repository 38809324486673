<template>
  <section class="privacy_page">
    <h1>{{ $t('privacy.key001') }}</h1>
    <p>
      {{ $t('privacy.key002') }}
      <a href="https://www.htc.com/tw/terms/privacy/" target="_blank">
        <strong>{{ $t('privacy.key003') }}</strong>
      </a>
      {{ $t('privacy.key004') }}
    </p>

    <h2>{{ $t('privacy.key005') }}</h2>
    <p>{{ $t('privacy.key006') }}</p>

    <h2>{{ $t('privacy.key007') }}</h2>
    <p>{{ $t('privacy.key008') }}</p>
    <p>{{ $t('privacy.key009') }}</p>
    <p>{{ $t('privacy.key010') }}</p>

    <p>{{ $t('privacy.key011') }}</p>
    <br />
    <router-link :to="{ path: `/${currentLanguage}/legal/legal-versions`, query: { legal: 'privacy' } }" class="legal-versions">
      <h2>{{ $t('legal.key007') }}</h2>
    </router-link>
  </section>
</template>
<script>
export default {
  name: 'LegalPage',
  components: {},
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
