<template>
  <swiper
    class="carousel-container slide_height"
    :loop="loop && slideItem.length > 0"
    :modules="modules"
    :centered-slides="centeredSlides"
    :navigation="navigation"
    :breakpoints="breakpoints"
    :speed="speed"
    :observer="observer"
    :observeParents="observeParents"
    :pagination="pagination"
    ref="mySwiper"
    @slideChange="onSlideChange"
    v-show="slideItem.length > 0"
  >
    <swiper-slide v-for="(item, index) in slideItem" :key="index" class="slide-item">
      <div class="slide_content">
        <!-- type = img -->
        <div class="slide_content img" v-if="item.type === 'image'">
          <router-link v-if="item.routerType === 'router'" :to="`/${currentLanguage}${item.routerLink}`">
            <picture>
              <source media="(max-width: 990px)" :srcset="item.mobileSrc" v-if="item.mobileSrc" />
              <img :src="item.src" :alt="item.alt" />
            </picture>
          </router-link>
          <a v-else-if="item.routerType === 'external-link'" :href="item.routerLink" target="_blank">
            <picture>
              <source media="(max-width: 990px)" :srcset="item.mobileSrc" v-if="item.mobileSrc" />
              <img :src="item.src" :alt="item.alt" />
            </picture>
          </a>
          <div v-else>
            <picture>
              <source media="(max-width: 990px)" :srcset="item.mobileSrc" v-if="item.mobileSrc" />
              <img :src="item.src" :alt="item.alt" />
            </picture>
          </div>
        </div>
        <!-- type = yt-video -->
        <div class="slide_content yt-video" v-if="item.type === 'yt-video'">
          <img src="@/assets/img/products/Transparent_BG.png" alt="" />
          <div :id="'player-' + index" class="yt-video-iframe"></div>
        </div>
        <!-- type = video -->
        <div class="slide_content video" v-if="item.type === 'video'">
          <video :ref="'video-' + index" :src="item.src" muted playsinline webkit-playsinline draggable="true" :controls="!isMobile"></video>
        </div>
      </div>
    </swiper-slide>

    <div class="swiper-pagination"></div>
    <button type="button" class="swiper-button-prev" v-if="!isMobile"></button>
    <button type="button" class="swiper-button-next" v-if="!isMobile"></button>

    <!-- home arrow icon -->
    <button type="button" class="swiper-button-prev" v-if="propPage === 'home'">
      <i class="bi bi-caret-left-fill"></i>
    </button>
    <button type="button" class="swiper-button-next" v-if="propPage === 'home'">
      <i class="bi bi-caret-right-fill"></i>
    </button>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import YouTubePlayer from 'youtube-player';

export default {
  name: 'SwiperComponent',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Navigation, Pagination],
    };
  },
  data() {
    return {
      players: {},
      isInitialed: false,
      currentIndex: 0,
      autoPlayTimer: null,
      isUnmount: false,
    };
  },
  props: {
    slideItem: {
      type: Array,
      required: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    centeredSlides: {
      type: Boolean,
      default: true,
    },
    navigation: {
      type: Object,
      default: () => ({
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      }),
    },
    pagination: {
      type: [Object, Boolean],
      default: false,
    },
    breakpoints: {
      type: Object,
      default: () => ({
        576: { slidesPerView: 1.75, spaceBetween: 50 },
        0: { slidesPerView: 1.2, spaceBetween: 10 },
      }),
    },
    speed: {
      type: Number,
      default: 500,
    },
    observer: {
      type: Boolean,
      default: true,
    },
    observeParents: {
      type: Boolean,
      default: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    propPage: {
      type: String,
      default: '',
    },
  },
  watch: {
    slideItem: {
      handler(images) {
        this.isInitialed = false;

        this.$nextTick(() => {
          images.forEach((item, index) => {
            if (item.type === 'yt-video') {
              const uniqueKey = item.src || `video-${index}`;
              const player = YouTubePlayer(`player-${index}`, {
                videoId: uniqueKey,
                playerVars: {
                  autoplay: 0,
                  mute: 1,
                  rel: 0,
                  controls: this.isMobile ? 0 : 1,
                },
              });

              this.players[uniqueKey] = player;

              player.on('stateChange', (event) => {
                if (event.data === 0) {
                  const swiper = document.querySelector('.swiper').swiper;
                  if (this.currentIndex === swiper.realIndex) {
                    swiper.slideNext();
                  }
                }
              });
            }
          });

          this.isInitialed = true;
          const swiper = document.querySelector('.swiper')?.swiper;
          if (swiper) {
            this.onSlideChange(swiper);
          }
        });
      },
      deep: true,
    },
  },
  beforeUnmount() {
    this.isUnmount = true;
    // prevent
    clearTimeout(this.autoPlayTimer);

    // clear yt player
    if (typeof this.players === 'object' && this.players !== null) {
      Object.keys(this.players).forEach((key) => {
        this.players[key].destroy();
      });
    }
    this.players = {};
  },
  methods: {
    getWrapperTag(type) {
      switch (type) {
        case 'router':
          return 'router-link';
        case 'external-link':
          return 'a';
        default:
          return 'div';
      }
    },
    onSlideChange(swiper) {
      if (!this.isInitialed || this.isUnmount || this.slideItem.length === 0) {
        return;
      }

      this.currentIndex = swiper.realIndex;
      clearTimeout(this.autoPlayTimer);

      const preIndex = this.currentIndex - 1 < 0 ? this.slideItem.length - 1 : this.currentIndex - 1;
      const nextIndex = this.currentIndex + 1 >= this.slideItem.length ? 0 : this.currentIndex + 1;
      const bannerPreType = this.slideItem[preIndex].type;
      const bannerNextType = this.slideItem[nextIndex].type;

      this.pauseVideoBanner(bannerPreType, this.slideItem[preIndex].src, preIndex);
      this.pauseVideoBanner(bannerNextType, this.slideItem[nextIndex].src, nextIndex);

      const bannerImg = this.slideItem[this.currentIndex];
      const bannerType = bannerImg.type;

      if (bannerType === 'yt-video') {
        const uniqueKey = bannerImg.src;
        const player = this.players[uniqueKey];
        if (player) {
          player.seekTo(0);
          player.playVideo();
        }
      } else if (bannerType === 'image') {
        this.autoPlayTimer = setTimeout(() => {
          const swiper = document.querySelector('.swiper')?.swiper;
          if (swiper) {
            swiper.slideNext();
          }
        }, 5000);
      } else if (bannerType === 'video') {
        const videoEl = this.$refs[`video-${this.currentIndex}`];

        if (videoEl && videoEl[0]) {
          videoEl[0].currentTime = 0;
          videoEl[0].onended = () => {
            const swiper = document.querySelector('.swiper')?.swiper;
            swiper.slideNext();
          };

          setTimeout(() => {
            const videoEl = this.$refs[`video-${this.currentIndex}`];
            if (videoEl && videoEl[0]) {
              videoEl[0].play();
            }
          }, 500);
        }
      }
    },
    pauseVideoBanner(type, indexVideoId, currentIndex) {
      if (type === 'yt-video') {
        const uniqueKey = indexVideoId;
        const player = this.players[uniqueKey];
        player?.pauseVideo();
      } else if (type === 'video') {
        const videoEl = this.$refs[`video-${currentIndex}`];
        if (videoEl?.length) {
          videoEl[0].pause();
        }
      }
    },
  },
};
</script>

<style scoped></style>
