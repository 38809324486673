<template>
  <div v-show="show">
    <app-header></app-header>
    <div class="">
      <section class="index_page wrapper">
        <div class="banner">
          <SwiperComponent
            :slideItem="bannerImages"
            :loop="true"
            :centeredSlides="true"
            :navigation="{
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
            }"
            :pagination="{ clickable: true }"
            :breakpoints="{ slidesPerView: 1, spaceBetween: 0 }"
            :isMobile="isMobile"
            :propPage="pageName"
          />
          <div class="scroll_down">
            <div class="scroll">Scroll</div>
            <div class="divider">
              <div class="line"></div>
              <div class="scroll_line"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="info01_box">
      <div class="title">
        <h1>{{ $t('home.key001') }}</h1>
        <h1 class="EN">{{ $t('home.key002') }}</h1>
      </div>
      <p>
        {{ $t('home.key003') }}
        <br />
        {{ $t('home.key004') }}
      </p>
      <div class="info_box">
        <div class="item">
          <img src="../assets/img/index/musicUnchained/iconControlPerspective.png" alt="" />
          <h3>{{ $t('home.key005') }}</h3>
        </div>
        <div class="item">
          <img src="../assets/img/index/musicUnchained/iconTraverseDreamscapes.png" alt="" />
          <h3>{{ $t('home.key006') }}</h3>
        </div>
        <div class="item">
          <img src="../assets/img/index/musicUnchained/iconRealisticHolograms.png" alt="" />
          <h3>{{ $t('home.key007') }}</h3>
        </div>
        <div class="item">
          <img src="../assets/img/index/musicUnchained/iconHighlyInteractive.png" alt="" />
          <h3>{{ $t('home.key008') }}</h3>
        </div>
      </div>
    </section>
    <section class="info02_box">
      <dotlottie-player
        id="wave"
        src="https://lottie.host/d4b8b24c-7a01-407b-b92b-a3b4960cef97/h1uds58UUy.json"
        background="transparent"
        speed="1"
        loop
        autoplay
      ></dotlottie-player>
      <div class="text">
        <h1>{{ $t('home.key009') }}</h1>
        <br />
        <h1>{{ $t('home.key010') }}</h1>
        <p v-html="formattedHome_key11"></p>
        <router-link :to="`/${currentLanguage}/downloads`" class="download">
          {{ $t('home.key012') }}
          <img src="../assets/img/index/arrow-up.svg" alt="" />
        </router-link>
      </div>
      <div class="img_slide">
        <div class="slide_mask"></div>
        <div class="slide01"></div>
        <div class="slide02"></div>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
  <Loading v-show="loading"></Loading>
</template>
<script>
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
import { authenticateOAuth, getUserInfo } from '@/api/apiService';
import { banners as getBanners } from '@/api/apiService.js';
import Loading from '@/components/Loading.vue';
import * as NextPathLocalStorage from '@/localStorage/nextPath';
import SwiperComponent from '@/components/CarouselContainer.vue';

export default {
  name: 'IndexPage',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
    Loading,
    SwiperComponent,
  },
  setup() {
    const show = ref(false);
    const store = useStore();
    const router = useRouter();

    const sendCodeToBackend = async (provider, code) => {
      try {
        const response = await authenticateOAuth(provider, code);
        const userDetails = response.data.data;
        const jwt = userDetails.jwt;

        store.commit('setJwt', jwt);

        return jwt;
      } catch (error) {
        console.error('Failed to send authorization code:');
        throw error;
      }
    };

    onBeforeMount(async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        let code, provider;
        code = urlParams.get('code');
        if (!urlParams.get('provider')) {
          provider = 'google';
        } else {
          provider = urlParams.get('provider');
        }

        if (code && provider) {
          const jwt = await sendCodeToBackend(provider, code);

          // remove query string
          const newUrl = `${window.location.origin}${window.location.pathname}#/${store.state.currentLanguage}`;
          window.history.replaceState({}, '', newUrl);

          const nextPath = NextPathLocalStorage.get();
          if (nextPath) {
            router.push({
              path: `/${store.state.currentLanguage}/${nextPath}`,
            });
            NextPathLocalStorage.remove();
          } else {
            const userData = await getUserInfo(jwt, true);

            if (!userData) {
              return;
            }

            router.push({
              path: `/${store.state.currentLanguage}/`,
            });
          }
        }
      } catch (error) {
        alert('Login failed');
        console.error(error);
      } finally {
        show.value = true;
      }
    });

    return {
      show,
    };
  },
  async mounted() {
    setTimeout(() => {
      $('#wave')[0]?.play();
    }, 1000);
    await this.banners();
  },
  data() {
    return {
      bannerImages: [],
      loading: true,
      pageName: 'home',
    };
  },
  computed: {
    formattedHome_key11() {
      return this.$t('home.key011').replace(/\n/g, '<br>');
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    async banners() {
      try {
        this.loading = true;
        const langSetting = this.currentLanguage === 'zh_tw' ? 'zh-tw' : this.currentLanguage;
        const response = await getBanners(langSetting);

        this.bannerImages = response.data.data;
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../assets/styles/pages/index.scss';
</style>
