<template>
  <div id="app">
    <router-viewPage></router-viewPage>
    <announce-box v-show="displayAlert" @close-alert="hideAlert"></announce-box>
    <cookie-agree></cookie-agree>
    <Loading v-show="isLoading"></Loading>
  </div>
</template>

<script>
import AnnounceBox from '@/components/Announce.vue';
import CookieAgree from '@/components/Cookie.vue';
import RouterViewPage from '@/pages/RouterView.vue';
import Loading from '@/components/Loading.vue';
import { mapState } from 'vuex';
import { debounce } from 'lodash';

const OriginalResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends OriginalResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 2000);
    super(callback);
  }
};

export default {
  name: 'App',
  components: {
    'announce-box': AnnounceBox,
    'cookie-agree': CookieAgree,
    'router-viewPage': RouterViewPage,
    Loading,
  },
  data() {
    return {
      displayAlert: false,
    };
  },
  computed: {
    ...mapState({
      isMaintenance: (state) => state.isMaintenance,
    }),
    isLoading() {
      return this.isMaintenance === null;
    },
  },
  mounted() {
    if (!this.displayAlert) {
      document.body.style.overflow = '';
    }
  },
  methods: {
    hideAlert() {
      document.body.style.overflow = '';
      this.displayAlert = false;
    },
  },
};
</script>
