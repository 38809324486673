<template>
  <section class="maintenance-page">
    <div class="content">
      <img src="@/assets/img/public/BEATDAY_AlternateLogo_Multicolor.svg" alt="" class="BEATDAY-logo" />
      <h1>網站維護中</h1>
      <p>我們正在進行網站更新，請稍後再試。謝謝您的耐心等待！</p>
      <p>
        維護時間：<span>{{ time }}</span>
      </p>
      <button @click="refreshPage">重新整理</button>
    </div>
    <div class="img-box">
      <img src="@/assets/img/maintenance/Maintenance.webp" alt="Maintenance" class="maintenance-image" />
    </div>
  </section>
</template>

<script>
import { webStatus } from '@/api/lambdaApiService.js';
export default {
  name: 'MaintenancePage',
  components: {},
  props: {},
  data() {
    return {
      time: '',
    };
  },
  mounted() {
    this.webStatus();
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
    async webStatus() {
      try {
        const langSetting = this.currentLanguage === 'zh_tw' ? 'zh-tw' : this.currentLanguage;
        const response = await webStatus(langSetting);

        this.time = this.formatTimeRange(response.data.startTime, response.data.endTime);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
    // time format
    formatTimeRange(startTime, endTime) {
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      const formatDate = (date) => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const hh = String(date.getHours()).padStart(2, '0');
        const min = String(date.getMinutes()).padStart(2, '0');

        this.timeZone = date.toString().match(/\(([^)]+)\)$/)[1];
        return `${yyyy}/${mm}/${dd} ${hh}:${min}`;
      };

      const formattedStart = formatDate(startDate);
      const formattedEnd = formatDate(endDate);

      return `${formattedStart} ~ ${formattedEnd} (${this.timeZone})`;
    },
  },
};
</script>

<style lang="scss">
.maintenance-page {
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  gap: 8%;
  justify-content: center;
  padding: 30px;
  box-sizing: border-box;
  .img-box {
    position: relative;
  }
  .img-box::after {
    content: '';
    position: absolute;
    left: -15%;
    top: 0;
    width: 140%;
    height: 100%;
    background: radial-gradient(closest-side, rgb(245, 223, 127, 0.3), rgb(99, 83, 70, 0.2), rgb(99, 83, 70, 0));
    z-index: -1;
  }
  .maintenance-image {
    width: 100%;
    max-width: 400px;
    max-height: 100%;
  }
  img.BEATDAY-logo {
    max-width: 200px;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .content h1 {
    font-size: 3rem;
    color: rgb(250, 223, 107);
  }
  .content p,
  .content span {
    font-size: 1.2rem;
    color: #bdbdbd;
  }
  .content button {
    margin-top: 20px;
    padding: 15px 30px;
    color: #fff;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    background: linear-gradient(#1e1e1e, #1e1e1e) padding-box, linear-gradient(-30deg, #322828 5%, #febc37, #ffd95a 45%, #febc37 55%, #c07f00 70%, #4c3d3d) border-box;
    border-radius: 50em;
    border: 2px solid transparent;
    transition: all 1s;
  }
  .content button:hover {
    background: linear-gradient(#1e1e1eb0, #1e1e1e) padding-box, linear-gradient(-30deg, #322828 5%, #febc37, #ffd95a 45%, #febc37 55%, #c07f00 70%, #4c3d3d) border-box;
  }
}

@media (max-width: 991px) {
  .maintenance-page {
    flex-direction: column;
  }
  .content {
    order: 2;
  }
  .img-box {
    order: 1;
  }
}

@media (max-width: 576px) {
  .maintenance-page {
    gap: 2%;
  }
  .content {
    gap: 10px;
  }
}
</style>
