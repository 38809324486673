<template>
  <div class="marquee" v-show="parsedMessages.length > 0">
    <div class="marquee-content" ref="marqueeContent" :style="marqueeStyle">
      <p v-for="(formattedMessage, index) in parsedMessages" :key="index" v-html="formattedMessage"></p>
    </div>
  </div>
</template>

<script>
import { marquee } from '@/api/apiService.js';

export default {
  name: 'MarqueeText',
  data() {
    return {
      contentWidth: 0,
      animationDuration: 10,
      marquee: {},
      parsedMessages: [],
    };
  },
  computed: {
    marqueeStyle() {
      return {
        animationDuration: `${this.animationDuration}s`,
      };
    },
  },
  async mounted() {
    await this.fetchMarquee();
    // 計算內容的寬度
    this.$nextTick(() => {
      if (this.$refs.marqueeContent) {
        this.contentWidth = this.$refs.marqueeContent.offsetWidth; // 獲取內容的寬度
        this.animationDuration = this.calculateAnimationDuration(this.contentWidth);
      }
    });
  },
  methods: {
    async fetchMarquee() {
      try {
        const langSetting = this.currentLanguage === 'zh_tw' ? 'zh-tw' : this.currentLanguage;
        const path = this.$route.name;
        const currentRoute = this.$route.path;
        const segments = currentRoute.split('/');
        const currentNameIndex = segments.indexOf(path);

        // marqueeType
        let marqueeType, eventName;

        if (currentRoute.includes('/event/')) {
          marqueeType = 'EVENT';
          eventName = path;
        } else if (currentRoute.includes('/product-info/')) {
          marqueeType = 'PRODUCT';
          eventName = '';
        } else {
          marqueeType = 'GENERAL';
          eventName = '';
        }

        // productId
        const productId = currentNameIndex !== -1 && currentNameIndex + 1 < segments.length ? segments[currentNameIndex + 1] : '';

        const response = await marquee(langSetting, marqueeType, productId, eventName);
        this.marquee = response.data.data;

        this.parsedMessages = this.marquee.map((item) => {
          const message = item.message || '';
          return this.parsedBBCode(message);
        });
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
    parsedBBCode(message) {
      return message
        .replace(/\[b\]/g, '<b>')
        .replace(/\[\/b\]/g, '</b>')
        .replace(/\[i\]/g, '<i>')
        .replace(/\[\/i\]/g, '</i>')
        .replace(/\[u\]/g, '<u>')
        .replace(/\[\/u\]/g, '</u>')
        .replace(/\[color=(.*?)\]/g, '<span style="color:$1;" class="font">')
        .replace(/\[\/color\]/g, '</span>');
    },
    calculateAnimationDuration(width) {
      const viewportWidth = window.innerWidth;
      const baseSpeed = 10;
      const duration = (width / viewportWidth) * baseSpeed;
      return duration > baseSpeed ? duration : baseSpeed;
    },
  },
};
</script>

<style scoped>
.marquee {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  background: rgb(23, 23, 23, 0.6);
  backdrop-filter: blur(5px);
  padding: 5px 0;
}

.marquee-content {
  display: inline-block;
  padding-left: 100%;
  animation: marquee linear infinite;
}
.marquee-content p {
  display: inline;
  padding: 0 20px;
}
span.font {
  font-size: 10px;
}
.marquee-content span b {
  font-weight: bold !important;
}
.marquee-content span i {
  font-style: italic !important;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media (max-width: 1200px) {
}

@media (max-width: 991px) {
}

@media (max-width: 576px) {
}

@media (max-width: 414px) {
  .marquee-content {
    span {
      font-size: 10px;
    }
    p,
    span,
    i,
    u {
      font-size: 10px;
    }
    span.font {
      font-size: 10px;
    }
  }
}
</style>
