<template>
  <section class="my-order">
    <h1>{{ $t('my-orders.key001') }}</h1>
    <div class="order-table-box" v-if="orders">
      <div class="page-info">
        <p class="page-unm">{{ displayRange() }} , Page {{ currentPage }} of {{ totalPages }}</p>
      </div>
      <table class="orders-table" v-if="!isMobile">
        <thead>
          <tr>
            <th>{{ $t('my-orders.key002') }}</th>
            <th>{{ $t('my-orders.key003') }}</th>
            <th>{{ $t('my-orders.key004') }}</th>
            <th>{{ $t('my-orders.key005') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(order, index) in chunkedOrders[currentPage - 1]" :key="index">
            <td>{{ order.createdAt }}</td>
            <td>{{ order.id }}</td>
            <td>{{ order.amount }}</td>
            <td :class="{ notice: order.statusCode === 'SUCCESS' }" class="darkColor">
              {{ order.status }}
            </td>
            <td>
              <div class="order-details-btn" @click="viewOrderDetails(order.id)">{{ $t('my-orders.key006') }}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="orders-table-mobile" v-for="(order, index) in chunkedOrders[currentPage - 1]" :key="index" v-else>
        <div class="title">
          <div class="date">{{ order.createdAt }}</div>
          <div :class="{ notice: order.statusCode === 'SUCCESS' }" class="status darkColor">{{ order.status }}</div>
        </div>
        <div class="list">
          <div class="list-item">
            <div class="list-title">{{ $t('my-orders.key003') }}</div>
            <div class="list-content">{{ order.id }}</div>
          </div>
          <div class="list-item">
            <div class="list-title">{{ $t('my-orders.key002') }}</div>
            <div class="list-content">{{ order.createdAt }}</div>
          </div>
          <div class="list-item">
            <div class="list-title">{{ $t('my-orders.key004') }}</div>
            <div class="list-content">{{ order.amount }}</div>
          </div>
          <div class="list-item">
            <div class="list-title">{{ $t('my-orders.key005') }}</div>
            <div class="list-content darkColor" :class="{ notice: order.statusCode === 'SUCCESS' }">{{ order.status }}</div>
          </div>
        </div>
        <div class="detail-button">
          <div class="order-details-btn" @click="viewOrderDetails(order.id)">{{ $t('my-orders.key006') }}</div>
        </div>
      </div>
      <div class="pagination">
        <button @click="previousPage" :disabled="currentPage === 1">
          <i class="bi bi-chevron-left"></i>
        </button>
        <span v-for="page in visiblePages" :key="page" @click="goToPage(page)" :class="{ active: currentPage === page }">
          {{ page }}
        </span>
        <span v-if="hasMoreOrders" @click="fetchMoreOrders">...</span>
        <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages && !hasMoreOrders">
          <i class="bi bi-chevron-right"></i>
        </button>
      </div>
    </div>
    <div class="order-empty" v-if="!orders">
      <h2>{{ $t('my-orders.key007') }}</h2>
      <router-link :to="`/${currentLanguage}/products`" class="button">
        <div class="button_style_linear">{{ $t('my-orders.key008') }}</div>
      </router-link>
    </div>
  </section>
  <Loading v-show="loading"></Loading>
</template>

<script>
import { getMyOrder } from '@/api/apiService';
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import { chunk } from 'lodash-es';

export default {
  name: 'ShoppingCart',
  components: {
    Loading,
  },
  data() {
    return {
      currentPage: 1, // 當前頁面
      maxVisiblePages: 5, // 每次顯示的頁碼數
      mobileItemsPerPage: 5,
      desktopItemsPerPage: 10,
      orders: [],
      loading: false,
      // for query next page orders
      lastKeyId: undefined,
      lastKeyCreatedAt: undefined,
    };
  },
  mounted() {
    this.fetchOrders();
  },
  computed: {
    ...mapState(['jwt']),
    isMobile() {
      return this.$store.state.isMobile;
    },
    // 每頁顯示的項目數量
    itemsPerPage() {
      return this.isMobile ? this.mobileItemsPerPage : this.desktopItemsPerPage;
    },
    chunkedOrders() {
      return chunk(this.orders, this.itemsPerPage);
    },
    totalPages() {
      return this.chunkedOrders.length;
    },
    hasMoreOrders() {
      return this.lastKeyId != null;
    },
    visiblePages() {
      if (!this.totalPages) return [];

      let startPage, endPage;

      if (this.totalPages <= this.maxVisiblePages) {
        startPage = 1;
        endPage = this.totalPages;
      } else {
        const middle = Math.floor(this.maxVisiblePages / 2);

        if (this.currentPage <= middle) {
          startPage = 1;
          endPage = this.maxVisiblePages;
        } else if (this.currentPage + middle >= this.totalPages) {
          startPage = this.totalPages - this.maxVisiblePages + 1;
          endPage = this.totalPages;
        } else {
          startPage = this.currentPage - middle;
          endPage = this.currentPage + middle;
        }
      }

      return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    },
  },
  watch: {
    currentPage() {
      this.scrollToTop();
    },
    itemsPerPage(newVal, oldVal) {
      // 計算當前頁面的第一筆訂單的索引
      const firstOrderIndex = (this.currentPage - 1) * oldVal + 1;

      // 計算新的 currentPage
      let newPage = Math.max(1, Math.ceil(firstOrderIndex / newVal));

      // 確保 newPage 不超過 totalPages
      this.currentPage = Math.min(newPage, this.totalPages);
    },
  },
  methods: {
    async fetchOrders() {
      try {
        this.loading = true;

        const langSetting = this.currentLanguage === 'zh_tw' ? 'zh-tw' : this.currentLanguage;
        const queryOrderOptions = {
          lastKeyId: this.lastKeyId,
          lastKeyCreatedAt: this.lastKeyCreatedAt,
        };
        const response = await getMyOrder(this.jwt, langSetting, queryOrderOptions);
        const responseData = response.data.data;

        this.orders = this.orders.concat(this.transformOrderData(responseData.orders));

        this.lastKeyId = responseData.lastKey?.id;
        this.lastKeyCreatedAt = responseData.lastKey?.createdAt;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    transformOrderData(apiData) {
      return apiData.map((order) => ({
        amount: this.formatNumber(order.amount),
        createdAt: order.createdAt.split('T')[0],
        id: order.id,
        status: order.status,
        statusCode: order.statusCode,
      }));
    },
    async fetchMoreOrders() {
      if (this.hasMoreOrders) {
        await this.fetchOrders();
      }
    },
    async goToPage(page) {
      if (page === this.totalPages + 1 && this.hasMoreOrders) {
        const currentChunkOrders = this.chunkedOrders[this.currentPage - 1];
        await this.fetchOrders();

        if (currentChunkOrders.length === this.itemsPerPage) {
          this.nextPage();
        }

        return;
      }
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage = this.currentPage + 1;
      }
    },
    scrollToTop() {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
    },
    displayRange() {
      const start = (this.currentPage - 1) * this.itemsPerPage + 1;
      const end = Math.min(this.currentPage * this.itemsPerPage, this.orders.length);
      return `${start} - ${end}`;
    },
    //Amount format
    formatNumber(value) {
      return value.toLocaleString('en-US');
    },
    viewOrderDetails(orderId) {
      this.$router.push({
        path: `/${this.currentLanguage}/member/order-details/${orderId}`,
      });
    },
  },
};
</script>

<style lang="scss"></style>
