<template>
  <section class="user_policy">
    <div class="wpb_wrapper">
      <h1>{{ $t('user-policy.key001') }}</h1>
      <p>{{ $t('user-policy.key002') }}</p>
      <p>{{ $t('user-policy.key003') }}</p>
      <p>{{ $t('user-policy.key004') }}</p>
      <p>{{ $t('user-policy.key005') }}</p>
      <p>{{ $t('user-policy.key006') }}</p>
      <p>{{ $t('user-policy.key007') }}</p>
      <h2>{{ $t('user-policy.key008') }}</h2>
      <p>{{ $t('user-policy.key009') }}</p>
      <h2>{{ $t('user-policy.key010') }}</h2>
      <p>{{ $t('user-policy.key011') }}</p>
      <h2>{{ $t('user-policy.key012') }}</h2>
      <p>{{ $t('user-policy.key013') }}</p>
      <h2>{{ $t('user-policy.key014') }}</h2>
      <p>{{ $t('user-policy.key015') }}</p>
      <p>{{ $t('user-policy.key016') }}</p>
      <h2>{{ $t('user-policy.key017') }}</h2>
      <p>{{ $t('user-policy.key018') }}</p>
      <p>{{ $t('user-policy.key019') }}</p>
      <p>{{ $t('user-policy.key020') }}</p>
      <p>{{ $t('user-policy.key021') }}</p>
      <p>{{ $t('user-policy.key022') }}</p>
      <h2>{{ $t('user-policy.key023') }}</h2>
      <p>{{ $t('user-policy.key024') }}</p>
      <p>{{ $t('user-policy.key025') }}</p>
      <p>{{ $t('user-policy.key026') }}</p>
      <p>{{ $t('user-policy.key027') }}</p>
      <p>{{ $t('user-policy.key028') }}</p>
      <h2>{{ $t('user-policy.key029') }}</h2>
      <p>{{ $t('user-policy.key030') }}</p>
      <h2>{{ $t('user-policy.key031') }}</h2>
      <p>{{ $t('user-policy.key032') }}</p>
      <p>{{ $t('user-policy.key033') }}</p>
      <h2>{{ $t('user-policy.key034') }}</h2>
      <p>{{ $t('user-policy.key035') }}</p>
      <p>{{ $t('user-policy.key036') }}</p>
      <p>{{ $t('user-policy.key037') }}</p>
      <p>{{ $t('user-policy.key038') }}</p>
      <p>{{ $t('user-policy.key039') }}</p>
      <p>{{ $t('user-policy.key040') }}</p>
      <p>{{ $t('user-policy.key041') }}</p>
      <p>{{ $t('user-policy.key042') }}</p>
      <p>{{ $t('user-policy.key043') }}</p>
      <p>{{ $t('user-policy.key044') }}</p>
      <h2>{{ $t('user-policy.key045') }}</h2>
      <p>{{ $t('user-policy.key046') }}</p>
      <p>{{ $t('user-policy.key047') }}</p>
      <p>{{ $t('user-policy.key048') }}</p>
      <p>{{ $t('user-policy.key049') }}</p>
      <p>{{ $t('user-policy.key050') }}</p>
      <p>{{ $t('user-policy.key051') }}</p>
      <h2>{{ $t('user-policy.key052') }}</h2>
      <p>{{ $t('user-policy.key053') }}</p>
      <h2>{{ $t('user-policy.key054') }}</h2>
      <p>
        {{ $t('user-policy.key055') }}
        <a href="https://www.htc.com/tw/terms/privacy/" target="_blank">
          {{ $t('user-policy.key056') }}
        </a>
        {{ $t('user-policy.key057') }}
      </p>
      <h2>{{ $t('user-policy.key058') }}</h2>
      <p>{{ $t('user-policy.key059') }}</p>
      <p>{{ $t('user-policy.key060') }}</p>
      <p>{{ $t('user-policy.key061') }}</p>
      <p>{{ $t('user-policy.key062') }}</p>
      <p>{{ $t('user-policy.key063') }}</p>
      <p>{{ $t('user-policy.key064') }}</p>
      <p>{{ $t('user-policy.key065') }}</p>
      <p>{{ $t('user-policy.key066') }}</p>
      <p>{{ $t('user-policy.key067') }}</p>
      <h2>{{ $t('user-policy.key068') }}</h2>
      <p>{{ $t('user-policy.key069') }}</p>
      <p>{{ $t('user-policy.key070') }}</p>
      <p>{{ $t('user-policy.key071') }}</p>
      <h2>{{ $t('user-policy.key072') }}</h2>
      <p>{{ $t('user-policy.key073') }}</p>
      <p>{{ $t('user-policy.key074') }}</p>
      <p>{{ $t('user-policy.key075') }}</p>
      <p>{{ $t('user-policy.key076') }}</p>
      <h2>{{ $t('user-policy.key077') }}</h2>
      <p>{{ $t('user-policy.key078') }}</p>
      <h2>{{ $t('user-policy.key079') }}</h2>
      <p>{{ $t('user-policy.key080') }}</p>
      <p>{{ $t('user-policy.key081') }}</p>
      <h2>{{ $t('user-policy.key082') }}</h2>
      <p>{{ $t('user-policy.key083') }}</p>
      <p>{{ $t('user-policy.key084') }}</p>
      <p>{{ $t('user-policy.key085') }}</p>
      <p>{{ $t('user-policy.key086') }}</p>
      <p>{{ $t('user-policy.key087') }}</p>
      <p>{{ $t('user-policy.key088') }}</p>
      <p>{{ $t('user-policy.key089') }}</p>
      <p>{{ $t('user-policy.key090') }}</p>
      <p>{{ $t('user-policy.key091') }}</p>
      <p>{{ $t('user-policy.key092') }}</p>
      <p>{{ $t('user-policy.key093') }}</p>
      <p>{{ $t('user-policy.key094') }}</p>
      <h2>{{ $t('user-policy.key095') }}</h2>
      <p>{{ $t('user-policy.key096') }}</p>
      <h2>{{ $t('user-policy.key097') }}</h2>
      <p>{{ $t('user-policy.key098') }}</p>
      <p>{{ $t('user-policy.key099') }}</p>
      <h2>{{ $t('user-policy.key100') }}</h2>
      <p>{{ $t('user-policy.key101') }}</p>
      <p>{{ $t('user-policy.key102') }}</p>
      <h2>{{ $t('user-policy.key103') }}</h2>
      <p>{{ $t('user-policy.key104') }}</p>
      <h2>{{ $t('user-policy.key105') }}</h2>
      <p>{{ $t('user-policy.key106') }}</p>
      <h2>{{ $t('user-policy.key107') }}</h2>
      <p>{{ $t('user-policy.key108') }}</p>
      <p>{{ $t('user-policy.key109') }}</p>
      <p>{{ $t('user-policy.key110') }}</p>
      <p>{{ $t('user-policy.key111') }}</p>
      <h2>{{ $t('user-policy.key112') }}</h2>
      <p>
        {{ $t('user-policy.key113') }}
        <br />
        <a href="mailto:contact@beatday.com"> {{ $t('user-policy.key114') }} contact&#64;beatday&#46;com </a>
      </p>

      <p>
        {{ $t('user-policy.key115') }}
        <br />
        {{ $t('user-policy.key116') }}
        <br />
        {{ $t('user-policy.key117') }}
        <br />
        <a href="mailto:contact@beatday.com"> contact&#64;beatday&#46;com </a>
      </p>
      <router-link :to="{ path: `/${currentLanguage}/legal/legal-versions`, query: { legal: 'user-policy' } }" class="legal-versions">
        <h2>{{ $t('legal.key007') }}</h2>
      </router-link>
    </div>
  </section>
</template>
<script>
export default {
  name: 'LegalPage',
  components: {},
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
