// import Vue from 'vue';
import { createStore } from 'vuex';
import { getToken, setToken, removeToken } from './localStorage/jwt';
import { getNickname, setNickname } from './localStorage/nickNameStorage';
import { remove as removeNextPath } from './localStorage/nextPath';
import { get as getLoginMethod, set as setLoginMethod, remove as removeLoginMethod } from './localStorage/loginMethod';
import * as CurrentLanguageStorage from './localStorage/currentLanguageStorage';

// loginMethod
// 1: basic
// 2: google
// 3: facebook
// 4: apple
// 5: discord

const TEMP_USER_INFO_EXPIRY_MINUTES = 3;

export const store = createStore({
  state: {
    loginMethod: getLoginMethod() || null,
    currentLanguage: CurrentLanguageStorage.get() || 'zh_tw',
    jwt: getToken() || null,
    userId: null,
    nickName: getNickname() || null,
    tempUserInfo: null,
    isMaintenance: null, //維護頁面開關
    isMobile: window.innerWidth <= 991,
  },
  mutations: {
    setLanguage(state, lang) {
      state.currentLanguage = lang;
    },
    setJwt(state, jwt) {
      state.jwt = jwt;
      setToken(jwt);
    },
    setNickname(state, nickName) {
      state.nickName = nickName;
      setNickname(nickName);
    },
    setLoginMethod(state, loginMethod) {
      state.loginMethod = loginMethod;
      setLoginMethod(loginMethod);
    },
    clearJwt(state) {
      state.jwt = null;
      removeToken();
    },
    setTempUserInfo(state, userInfo) {
      if (userInfo) {
        state.tempUserInfo = {
          ...userInfo,
          expiryTime: new Date(new Date().getTime() + TEMP_USER_INFO_EXPIRY_MINUTES * 60 * 1000),
        };
      } else {
        state.tempUserInfo = null;
      }
    },
    clearTempUserInfo(state) {
      state.tempUserInfo = null;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setMaintenanceMode(state, value) {
      state.isMaintenance = value;
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
  },
  actions: {
    setLanguage({ commit }, lang) {
      commit('setLanguage', lang);
      CurrentLanguageStorage.set(lang);
    },
    logout({ commit }) {
      commit('clearJwt');
      commit('setNickname', null);
      commit('setLoginMethod', null);
      commit('setTempUserInfo', null);
      commit('setUserId', null);
      commit('setTempUserInfo', null);

      removeNextPath();
      removeLoginMethod();
    },
    toggleMaintenanceMode({ commit }, value) {
      commit('setMaintenanceMode', value);
    },
    updateIsMobile({ commit }) {
      commit('setIsMobile', window.innerWidth <= 991);
    },
  },
});

export default store;
