<template>
  <app-header></app-header>
  <section class="productInfo-wrapper">
    <SwiperComponent
      :slideItem="productsImg"
      :loop="true"
      :centeredSlides="true"
      :navigation="{
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      }"
      :pagination="false"
      :breakpoints="{
        576: { slidesPerView: 1.75, spaceBetween: 50 },
        0: { slidesPerView: 1.2, spaceBetween: 10 },
      }"
      :isMobile="isMobile"
    />
    <div id="detailInfo"></div>
    <section class="productInfo">
      <section class="mobile-select" v-if="isMobile && !buyingWindowVisible">
        <div class="buy_btn">
          <div class="mobile-mask"></div>
          <button class="button_style_linear mobile-select-btn" @click="buyingWindowToggle(true)">
            {{ $t('product-info.key001') }}
          </button>
        </div>
      </section>
      <div class="info-left">
        <h1 class="title">{{ productInfo.name }}</h1>
        <div class="time">
          <p>
            <i class="bi bi-calendar-week"></i>
            <span>{{ productInfo.time }}</span>
          </p>
        </div>
        <ul class="options">
          <li v-for="(content, index) in intoContents" :key="index" @click="scrollToContent(index)">
            {{ content.title }}
          </li>
        </ul>
        <div v-for="(intoContent, index) in intoContents" :key="index" class="into-list" ref="contentRefs">
          <h2 class="into-title">{{ intoContent.title }}</h2>
          <div v-show="intoContent.content" class="into-content" v-html="intoContent.content"></div>
          <div v-show="intoContent.image" class="into-content" v-html="intoContent.image" @click="planImageClick"></div>
        </div>
        <div class="notice">
          <p v-html="formattedReturnPolicy"></p>
        </div>
      </div>

      <div class="select-right" v-if="!isMobile || buyingWindowVisible">
        <transition name="fade" appear>
          <div v-if="showCartAlert" class="cart-alert">
            <i class="bi bi-cart3"></i>
            <p>{{ $t('product-info.key016') }}</p>
          </div>
        </transition>
        <div class="products_sticky" ref="stickyBox_PC">
          <div class="mobile-mask" v-if="isMobile"></div>
          <div class="close_arrow_icon" v-if="isMobile" @click="buyingWindowToggle(false)">
            <img src="../../assets/img/products/close_arrow_icon.svg" alt="" />
          </div>
          <div class="products_select_box" ref="stickyBox_MB">
            <div class="choose_box" id="plan_choose_box">
              <h2 class="choose_title" id="select_plan">
                {{ $t('product-info.key004') }}
              </h2>
              <div v-for="(plan, index) in products" :key="plan.planId" class="plan-box" :ref="'select_plan_' + index">
                <div
                  class="radioChoose"
                  :class="{
                    selected: selectedPlan === plan.planId,
                    disabled: !plan.isActive || plan.soldOut,
                  }"
                  style="display: flax; flex-direction: column"
                >
                  <div class="select-padding" @click="selectOption(plan.planId, index)">
                    <img :src="plan.optionImage" alt="" style="width: 100%" />
                    <h3>{{ plan.title }}</h3>
                    <p v-html="formatContent(plan.contents)"></p>
                  </div>
                  <div class="select-padding-bottom" v-show="selectedPlan === plan.planId">
                    <div class="choose_box">
                      <h2 class="choose_title" id="select_time">
                        {{ $t('product-info.key005') }} <span>{{ this.timeZone }}</span>
                      </h2>
                      <div class="disable" v-if="!selectedOption">
                        {{ $t('product-info.key006') }}
                      </div>
                      <div v-for="(session, indexSession) in selectedOption" :key="session.sessionId">
                        <div
                          :for="session.sessionId"
                          class="custom-radio radioChoose select-padding"
                          :class="{
                            selected: selectedSessionId === session.sessionId,
                            disabled: !session.isActive,
                          }"
                          @click="selectTime(indexSession, 1, index)"
                        >
                          {{ session.time }}
                        </div>
                      </div>
                    </div>
                    <div class="choose_box" ref="select_pack">
                      <h2 class="choose_title" id="select_session">
                        {{ $t('product-info.key007') }}
                        <div class="alertContent" ref="alertContent">
                          {{ ticketAlert }}
                        </div>
                      </h2>
                      <div class="disable" v-if="!selectedSessionId">
                        {{ $t('product-info.key006') }}
                      </div>
                      <div v-if="selectedSessionId">
                        <div v-for="pack in selectedSessionPacks" :key="pack.packId">
                          <div class="countChoose" :for="pack.packId">
                            <div class="chooseContent">
                              <div class="item">{{ pack.title }}</div>
                              <div class="price">
                                NT$
                                <span class="current_price">{{ formatNumber(pack.price) }}</span>
                                <span class="original_price" v-if="pack.originalPrice"> NT$ {{ formatNumber(pack.originalPrice) }} </span>
                              </div>
                            </div>
                            <div class="soldOut" v-if="pack.remainingQuantity === 0">
                              {{ $t('product-info.key015') }}
                            </div>
                            <div class="countBox" v-else>
                              <div class="chooseCount">
                                <img src="../../assets/img/products/minus.svg" @click="decreaseTicketCount(pack.sessionId, pack.packId)" alt="" />
                                <div class="count">
                                  {{ ticketSelection[getSessionPackId(pack.sessionId, pack.packId)]?.quantity || 0 }}
                                </div>
                                <img src="../../assets/img/products/plus.svg" @click="increaseTicketCount(pack.sessionId, pack.packId, pack.price)" alt="" />
                              </div>
                              <div class="countAlert" v-if="pack.remainingQuantity > 0 && pack.remainingQuantity < 10" v-html="formattedAddOn_key010(pack.remainingQuantity)"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="count_total">
                      <h2>
                        {{ $t('product-info.key009') }}
                        <span class="total">
                          NT$
                          <span class="current_price">{{ formatNumber(totalPrice) }}</span>
                        </span>
                      </h2>
                    </div>
                    <div class="alertContent" ref="alertContent">{{ alert }}</div>

                    <div class="add_item_icon">
                      <div @click="addToCart('goCart')" class="mobile-select-btn">
                        <button class="button_style_linear mobile-select-btn">
                          {{ $t('product-info.key010') }}
                        </button>
                      </div>
                      <button class="add_buy mobile-select-btn" @click="addToCart">
                        {{ $t('product-info.key011') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="plan.soldOut" class="soldOut-mask">
                  <span>{{ $t('product-info.key015') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
  <app-footer></app-footer>
  <Loading v-show="loading"></Loading>
</template>

<script>
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
import { getProductInfo } from '@/api/cartService.js';
import { setCartItems, getCartItems, setCartItemExpiry, getCartItemExpiry } from '@/localStorage/cartStorage.js';
import Loading from '@/components/Loading.vue';
import SwiperComponent from '@/components/CarouselContainer.vue';

export default {
  name: 'EmptyPage',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
    Loading,
    SwiperComponent,
  },
  props: {},

  data() {
    return {
      // isInitialed: false,

      isMobile: window.innerWidth <= 991,
      buyingWindowVisible: false,
      selectedPlan: null,
      selectedOption: null,
      selectedSessionPacks: null,
      selectedSessionId: null,
      ticketSelection: {},
      additionalProducts: null,
      alert: '',
      ticketAlert: '',
      showCartAlert: false,

      products: {},
      productInfo: {},
      intoContents: [],
      planImgUrl: '',

      productId: this.$route.params.id,
      loading: false,
      timeZone: null,
      productsImg: [],
    };
  },
  async mounted() {
    await this.fetchProductInfo();
    window.addEventListener('resize', this.updateIsMobile);
    $(window).on('scroll', this.handleScroll);
  },
  computed: {
    totalPrice() {
      let total = 0;

      for (const item of Object.values(this.ticketSelection)) {
        total += item.price * item.quantity;
      }

      return total;
    },
    formattedReturnPolicy() {
      // TODO: should remove in the future
      if (this.$route.params.id === '65f583e5-6858-4865-87d9-a422a7900ccc') {
        return `<a href='#/${this.currentLanguage}/return-policy-MiruWonderland' target="_blank">${this.$t('product-info.key003')}</a>`;
      } else if (this.$route.params.id === '251f9a64-06c2-4959-9d0c-a30ceaa9cf84') {
        return `<a href='#/${this.currentLanguage}/return-policy-MizukiLin' target="_blank">${this.$t('product-info.key003')}</a>`;
      }
      return '';
    },
  },

  methods: {
    handleScroll() {
      const scrollTop = $(window).scrollTop();
      const selectBox = $('.products_sticky');
      const offsetTop = 100;

      if (scrollTop > offsetTop && !this.isMobile) {
        selectBox.css({
          position: 'sticky',
          top: '1vh',
        });
      } else {
        selectBox.css({
          position: 'relative',
          top: 'auto',
        });
      }
    },
    queryScrollToDetailInfo(scrollToId) {
      const targetElement = document.getElementById(scrollToId);
      const offset = this.isMobile ? 80 : 30;
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - offset,
          behavior: 'smooth',
        });
      }
    },
    // 替換 \n 為 <br/>
    formatContent(content) {
      if (!content) {
        return;
      } else {
        return content.replace(/\n/g, '<br/>');
      }
    },
    async fetchProductInfo() {
      try {
        this.loading = true;
        const langSetting = this.currentLanguage === 'zh_tw' ? 'zh-tw' : this.currentLanguage;
        const response = await getProductInfo(this.productId, langSetting);

        this.products = response.data;
        this.productInfo = this.products.data;
        this.productsImg = this.productInfo.carouselItems;

        // time format
        this.productInfo.time = this.formatTimeRange(this.productInfo.startTime, this.productInfo.endTime);
        const plans = this.productInfo.plans;

        if (plans) {
          plans.forEach((plan) => {
            if (plan.sessions) {
              let planSoldOut = true;
              plan.sessions.forEach((session) => {
                if (session.startTime && session.endTime) {
                  session.time = this.formatTimeRange(session.startTime, session.endTime);
                }
                if (session.packs.some((pack) => pack.remainingQuantity !== 0)) {
                  planSoldOut = false;
                }
              });

              plan.soldOut = planSoldOut;
            }
          });
        }

        if (Array.isArray(this.productInfo.details)) {
          this.productInfo.details = this.productInfo.details.map((detail) => {
            if (detail.content && typeof detail.content === 'string') {
              return {
                ...detail,
                content: this.convertNewlinesToBr(detail.content),
              };
            }
            if (detail.image && typeof detail.image === 'string') {
              const images = detail.image.split('\n');
              return {
                ...detail,
                image: images
                  .map((image, imgIndex) => {
                    const idOrRef = detail.isAnchor ? `id="anchor-${imgIndex}"` : '';
                    return `<img src="${image}" ${idOrRef} style="width: 100%" />`;
                  })
                  .join('<br/>'),
              };
            }
            return detail;
          });
        }

        this.intoContents = this.productInfo.details;
        this.products = this.productInfo.plans;

        this.$nextTick(() => {
          const scrollToId = this.$route.query.scrollTo;
          if (scrollToId) {
            setTimeout(() => {
              this.loading = false;
              this.queryScrollToDetailInfo(scrollToId);
            }, 2000);
          } else {
            this.loading = false;
          }
        });
      } catch (error) {
        console.error('Error fetching productInfo:', error);
        this.$router.push(`/${this.currentLanguage}/products`);
      }
    },
    // Click plan image triggers plan selection
    planImageClick(event) {
      const target = event.target;
      if (target.tagName.toLowerCase() === 'img' && target.id.startsWith('anchor-')) {
        const imgIndex = parseInt(target.id.replace('anchor-', ''), 10);
        const plan = this.productInfo.plans.find((plan) => plan.anchorIndex === imgIndex);

        if (plan) {
          const planId = plan.planId;
          const index = this.productInfo.plans.indexOf(plan);
          this.selectOption(planId, index);
        }
      }
    },
    // content format
    convertNewlinesToBr(content) {
      if (typeof content === 'string') {
        return content.replace(/\n/g, '<br/>');
      }
      return content; // Return as is if not a string
    },
    // time format
    formatTimeRange(startTime, endTime) {
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      const formatDate = (date) => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const hh = String(date.getHours()).padStart(2, '0');
        const min = String(date.getMinutes()).padStart(2, '0');

        this.timeZone = '(' + date.toString().match(/\(([^)]+)\)$/)[1] + ')';
        return `${yyyy}/${mm}/${dd} ${hh}:${min} `;
      };

      const formattedStart = formatDate(startDate);
      const formattedEnd = formatDate(endDate);

      return `${formattedStart} ~ ${formattedEnd}`;
    },
    scrollToContent(index) {
      const contentRef = this.$refs.contentRefs[index];
      if (contentRef) {
        let offsetTop;
        if (this.isMobile) {
          offsetTop = contentRef.offsetTop - 90;
        } else {
          offsetTop = contentRef.offsetTop - 45;
        }
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
      }
    },
    //選擇方案
    async selectOption(planId, index) {
      this.selectedPlan = planId;
      this.selectedOption = this.products[index].sessions;
      await this.$nextTick();
      const stickyBox = this.isMobile ? this.$refs.stickyBox_MB : this.$refs.stickyBox_PC;
      const select_plan = this.$refs['select_plan_' + index][0];
      const offsetTop = select_plan.offsetTop;

      stickyBox.scrollTo({
        top: offsetTop - 40,
        behavior: 'smooth',
      });

      // 滾動到 anchorIndex 的錨點位置
      const anchorIndex = this.products[index]?.anchorIndex;
      if (anchorIndex !== -1 && typeof anchorIndex === 'number') {
        const anchorElement = document.getElementById(`anchor-${anchorIndex}`);
        if (anchorElement) {
          const rect = anchorElement.getBoundingClientRect();
          const offset = window.pageYOffset || document.documentElement.scrollTop;
          const top = rect.top + offset - 40;

          window.scrollTo({
            top: top,
            behavior: 'smooth',
          });
        }
      }

      this.clearSelectTime();

      if (this.selectedOption && this.selectedOption.length > 0) {
        for (let i = 0; i < this.selectedOption.length; i++) {
          if (this.selectedOption[i].isActive) {
            this.selectTime(i);
            break;
          }
        }
      }
    },

    async selectTime(timeIndex, type, planIndex) {
      this.selectedSessionPacks = this.selectedOption[timeIndex].packs;
      this.selectedSessionId = this.selectedOption[timeIndex].sessionId;

      await this.$nextTick();
      if (type === 1) {
        const stickyBox = this.isMobile ? this.$refs.stickyBox_MB : this.$refs.stickyBox_PC;
        const select_pack = this.$refs.select_pack[planIndex];
        const offsetTop = this.getDistanceToStickyBox(select_pack);

        stickyBox.scrollTo({
          top: offsetTop - 50,
          behavior: 'smooth',
        });
      }
    },
    getDistanceToStickyBox(element) {
      const stickyBox = this.isMobile ? this.$refs.stickyBox_MB : this.$refs.stickyBox_PC;
      let offset = element.offsetTop;
      let parent = element.offsetParent;

      while (parent && parent !== stickyBox) {
        offset += parent.offsetTop;
        parent = parent.offsetParent;
      }

      return offset;
    },
    clearSelectTime() {
      this.selectedSessionPacks = null;
      this.selectedSessionId = null;
    },
    getSessionPackId(sessionId, packId) {
      return sessionId + '-' + packId;
    },
    decreaseTicketCount(sessionId, packId) {
      const sessionPackId = this.getSessionPackId(sessionId, packId);
      if (!this.ticketSelection[sessionPackId]) {
        return;
      }

      if (this.ticketSelection[sessionPackId].quantity > 1) {
        this.ticketSelection[sessionPackId].quantity--;
        this.ticketAlert = '';
      } else {
        delete this.ticketSelection[sessionPackId];
      }
    },
    increaseTicketCount(sessionId, packId, packPrice) {
      const sessionPackId = this.getSessionPackId(sessionId, packId);
      if (!this.ticketSelection[sessionPackId]) {
        this.ticketSelection[sessionPackId] = {
          sessionId,
          packId,
          price: packPrice,
          quantity: 0,
        };
      }

      if (this.ticketSelection[sessionPackId].quantity < 10) {
        this.ticketAlert = '';
        this.ticketSelection[sessionPackId].quantity++;
      } else {
        this.ticketAlert = this.$t('product-info.key012');
        this.triggerAlertAnimation();
      }
    },
    triggerAlertAnimation() {
      const message = this.$refs.alertContent;
      message.style.animationName = 'none';

      setTimeout(() => {
        message.style.animationName = 'shakeOpacity';
      }, 0);
    },
    addToCart(type) {
      // GET localStorage
      const cartItems = getCartItems() || [];

      if (Object.keys(this.ticketSelection).length < 1) {
        this.alert = this.$t('product-info.key013');
        return;
      }

      // Define expiration time (24 hours)
      const EXPIRY_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const now = new Date().getTime();
      const expiry = getCartItemExpiry() || {};
      const newCartItems = [];

      for (const ticketItem of Object.values(this.ticketSelection)) {
        const { sessionId, packId, quantity } = ticketItem;
        const newItem = {
          productId: this.productId,
          sessionId,
          packId,
          quantity,
        };

        const existingIndex = cartItems.findIndex((item) => item.productId === newItem.productId && item.sessionId === newItem.sessionId && item.packId === newItem.packId);

        if (existingIndex !== -1) {
          const existingItem = cartItems[existingIndex];
          if (existingItem.quantity + quantity > 10) {
            this.ticketAlert = this.$t('product-info.key012');
            this.alert = this.$t('product-info.key014');
            this.triggerAlertAnimation();
            return;
          }
          this.ticketAlert = '';
          existingItem.quantity += quantity;
        } else {
          newCartItems.push(newItem);
        }

        // Set new expiry time for the product-session combination
        expiry[this.productId + '-' + this.selectedSessionId] = now + EXPIRY_DURATION;
      }
      // Save to localStorage
      setCartItems([...cartItems, ...newCartItems]);
      setCartItemExpiry(expiry);
      this.selectedPlan = null;
      this.selectedOption = null;
      this.selectedSessionPacks = null;
      this.ticketSelection = {};
      this.buyingWindowVisible = false;
      this.ticketAlert = '';
      this.alert = '';
      if (type === 'goCart') {
        this.$router.push(`/${this.currentLanguage}/cart`);
      } else {
        const stickyBox = this.isMobile ? this.$refs.stickyBox_MB : this.$refs.stickyBox_PC;
        this.showCartAlert = true;
        setTimeout(() => {
          this.showCartAlert = false;
        }, 1500);
        stickyBox.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },

    updateIsMobile() {
      this.isMobile = window.innerWidth <= 991;
    },
    buyingWindowToggle(boolean) {
      this.buyingWindowVisible = boolean;
    },

    formatNumber(value) {
      return value.toLocaleString('en-US');
    },
    formattedAddOn_key010(itemQuantity) {
      return this.$t('shopping-cart.addOn_key010', {
        quantity: `<span class='current_price'> ${itemQuantity} </span>`,
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/pages/products/products.scss';
</style>
