<!-- Header.vue -->
<template>
  <footer>
    <div class="footer">
      <div class="flex">
        <div class="logo-container">
          <img src="@/assets/img/public/VIVE_Originals_AlternateLogo_Digital_Primary_multicolor_White.svg" alt="VIVE_Originals_logo" />
          <img src="../assets/img/headerFooter/BEATDAY_LOGO.svg" alt="BEATDAY_LOGO" />
        </div>

        <div class="section">
          <h3>{{ $t('common.footer_key001') }}</h3>
          <div v-for="link in legalLinks" :key="link.text" class="link">
            <template v-if="link.isExternal">
              <a :href="link.url[currentLanguage] || link.url['default']" target="_blank">
                {{ link.text }}
              </a>
            </template>
            <template v-else>
              <router-link :to="`/${currentLanguage}/legal/${link.path}`" @click="scrollToTop">
                {{ link.text }}
              </router-link>
            </template>
          </div>
        </div>
        <div class="section">
          <h3>{{ $t('common.footer_key006') }}</h3>
          <div v-for="link in download" :key="link.text" class="link">
            <router-link :to="`/${currentLanguage}/${link.path}`" @click="scrollToTop">
              {{ link.text }}
            </router-link>
          </div>
        </div>
      </div>

      <div class="footer_flex">
        <div class="social-links">
          <div class="flex_item">
            <div class="divider"></div>
            <div class="follow-us">Follow Us</div>
          </div>
          <div class="flex_item">
            <a href="https://line.me/R/ti/p/@485ibcgx?oat__id=29053" target="_blank" class="line">
              <img src="@/assets/img/headerFooter/Line.svg" alt="" />
            </a>
            <a href="https://www.facebook.com/beatday.music" target="_blank" class="FB">
              <img src="@/assets/img/headerFooter/facebook.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/beatday.music/" target="_blank" class="instagram">
              <img src="@/assets/img/headerFooter/Instagram.svg" alt="" />
            </a>
            <a href="https://twitter.com/beatday_verse" target="_blank" class="twitter">
              <img src="@/assets/img/headerFooter/twitter.svg" alt="" />
            </a>
            <a href="https://www.youtube.com/channel/UCtZsfDkAPwPqOzlENXSjFPA" target="_blank" class="youtube">
              <img src="@/assets/img/headerFooter/youtube.svg" alt="" />
            </a>
          </div>

          <div class="flex_item language">
            <div :class="{ disabled: currentLanguage === 'zh_tw' }" @click="changeLanguage('zh_tw')">繁中</div>
            <div>&nbsp;|&nbsp;</div>
            <div :class="{ disabled: currentLanguage === 'en' }" @click="changeLanguage('en')">EN</div>
            <div>&nbsp;|&nbsp;</div>
            <div :class="{ disabled: currentLanguage === 'ja' }" @click="changeLanguage('ja')">日本語</div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">© 2024 HTC Corporation. VIVE ORIGINALS</div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      legalLinks: [
        {
          text: this.$t('common.footer_key002'),
          path: 'privacy',
          isExternal: false,
        },
        {
          text: this.$t('common.footer_key003'),
          url: {
            en: 'https://www.htc.com/us/terms/cookies/',
            zh_tw: 'https://www.htc.com/tw/terms/cookies/',
            ja: 'https://www.htc.com/jp/terms/cookies/',
            default: 'https://www.htc.com/us/terms/cookies/',
          },
          isExternal: true,
        },
        {
          text: this.$t('common.footer_key004'),
          path: 'user-policy',
          isExternal: false,
        },
        {
          text: this.$t('common.footer_key005'),
          url: {
            en: 'https://www.htc.com/us/terms/product-security/',
            zh_tw: 'https://www.htc.com/tw/terms/product-security/',
            ja: 'https://www.htc.com/jp/terms/product-security/',
            default: 'https://www.htc.com/us/terms/product-security/',
          },
          isExternal: true,
        },
        {
          text: this.$t('common.footer_key009'),
          path: 'SAR-policy',
          isExternal: false,
        },
      ],
      download: [
        {
          text: this.$t('common.footer_key007'),
          path: 'downloads',
        },
        {
          text: this.$t('common.footer_key008'),
          path: 'faq/beatday-usage',
        },
      ],
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    changeLanguage(newLang) {
      // 獲取當前的查詢參數
      const currentQuery = this.$route.query;

      // 獲取當前路徑，並解析移除語系前綴部分
      let currentPath = this.$route.path;
      let parts = currentPath.split('/');
      let desiredPart = parts.slice(2).join('/'); // 移除前兩部分 (語系 + 首個 `/`)

      // 組合新路徑
      let newCurrentPath = `/${newLang}/${desiredPart}`;
      this.$router.push({
        path: newCurrentPath,
        query: currentQuery, // 保留查詢參數
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import '../assets/styles/components/footer.scss';
</style>
