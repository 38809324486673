import axios from './axios';
import store from '../vuex';
import router from '../router';

const basicLogin = async (loginData) => {
  const response = await axios.post('/api/user/login', loginData);

  const jwt = response.data.data.jwt;
  store.commit('setJwt', jwt);

  return jwt;
};

const signUp = (registerData) => {
  return axios.put('/api/user/register', registerData);
};

const sendVerification = (account) => {
  return axios.post('/api/user/register/verificationCode', account);
};
const resendVerification = (account) => {
  return axios.put('/api/user/register/verificationCode', account);
};

const sendVerificationForgetPSW = (account) => {
  return axios.post('/api/user/forgetPassword/verificationCode', account);
};
const checkVerificationForgetPSW = (data) => {
  return axios.put('/api/user/forgetPassword/verificationCode', data);
};
const updatePassword = (data) => {
  return axios.put('/api/user/forgetPassword', data);
};

const userChangeVerify = (data, jwt) => {
  return axios.put(
    '/api/user/info/password/verificationCode',
    {
      code: data.code,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
};

const userResetPassword = (data, jwt) => {
  return axios.put(
    '/api/user/info/password',
    {
      password: data.password,
      jwt: data.jwt,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
};

// get verifyCode
const getCodeToVerifyEmail = (data, jwt) => {
  return axios.post(
    '/api/user/info/emailUpdate',
    {
      contactEmail: data.contactEmail,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
};

// update contactEmail
const updateEmail = (data, jwt) => {
  store.commit('setTempUserInfo', null);

  return axios.put(
    '/api/user/info/emailUpdate',
    {
      contactEmail: data.contactEmail,
      code: data.code,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
};

// user info
// if returns null, it means the page has been redirected
const getUserInfo = async (jwt, checkUserNecessaryFields = false) => {
  const tempUserInfo = store.state.tempUserInfo;

  let userInfo = null;
  if (tempUserInfo && new Date() < new Date(tempUserInfo.expiryTime)) {
    userInfo = tempUserInfo;
  }

  if (!userInfo) {
    const userInfoResponse = await axios.get('/api/user/info', {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    userInfo = userInfoResponse.data.data;
    store.commit('setTempUserInfo', userInfo);
  }

  store.commit('setLoginMethod', userInfo.currentLoginMethod);
  store.commit('setUserId', userInfo.uid);
  store.commit('setNickname', userInfo.nickname?.trim() || '');

  // not need to check, return directly
  if (!checkUserNecessaryFields) {
    return userInfo;
  }

  // check pass, return
  if (userInfo.name && userInfo.contactEmail) {
    return userInfo;
  }

  // Check if already on the register-memberInfo page to prevent infinite redirection.
  // Though it is not happened now.
  if (router.currentRoute.value.fullPath !== `/${store.state.currentLanguage}/register-memberInfo`) {
    router.push({
      path: `/${store.state.currentLanguage}/register-memberInfo`,
    });
    return null;
  }

  return userInfo;
};

const updateUserInfo = async (data, jwt) => {
  await axios.put(
    '/api/user/info/updateUserInfo',
    {
      info: data.info,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );

  store.commit('setTempUserInfo', null);

  return;
};

// send gift
const sendGift = (data, jwt) => {
  return axios.put(
    '/api/user/info/sendGift',
    {
      collectionStage: data.collectionStage,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
};

//reset-psw
const resetPSWSendVerCode = (jwt) => {
  return axios.post('/api/user/info/password/verificationCode', null, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};
const resetPSWVerCodeCheck = (code, jwt) => {
  return axios.put('/api/user/info/password/verificationCode', code, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};
const resetPSW = (PSWData, jwt) => {
  return axios.put('/api/user/info/password', PSWData, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

// oauth login
const googleAuth = (data) => {
  return axios.post('/api/user/auth/google', {
    code: data.code,
  });
};

const facebookAuth = (data) => {
  return axios.put('/api/user/auth/facebook', {
    code: data.code,
  });
};

const appleAuth = (data) => {
  return axios.put('/api/user/auth/apple', {
    code: data.code,
  });
};

const discordAuth = (data) => {
  return axios.put('/api/user/auth/facebdiscordook', {
    code: data.code,
  });
};

// Serial Number
const redeemTicket = (code, jwt) => {
  return axios.post(
    '/api/user/redeem',
    {
      code,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
};

// Ticket Section

const getTicketInfo = (jwt) => {
  return axios.get('/api/user/tickets', {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};
const checkTicketState = (timestamp, jwt) => {
  return axios.post(
    '/api/user/tickets/check',
    {
      timestamp,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
};

const bindAccount = async (email, jwt) => {
  await axios.post(
    '/api/user/binding',
    {
      email: email.toLowerCase(),
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );

  store.commit('setLoginMethod', 1);
  store.commit('setTempUserInfo', null);

  return;
};

const authenticateOAuth = (provider, code) => {
  return axios.post(`/api/user/auth/${provider}`, { code });
};

const getPresignedUrl = (fileType, jwt) => {
  store.commit('setTempUserInfo', null);

  return axios.get(`/api/user/avatar/presigned-url?fileType=${fileType}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

const deleteUser = (jwt) => {
  return axios.delete('/api/user/', {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

const removeAvatar = (fileType, jwt) => {
  store.commit('setTempUserInfo', null);
  return axios.delete(`/api/user/avatar?fileType=${fileType}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

const removeAvatarCache = (fileType, jwt) => {
  return axios.delete(`/api/user/avatar/cache?fileType=${fileType}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

const DEFAULT_QUERY_MY_ORDERS_LIMIT = 100;
//My Order
/**
 * @param {string} jwt
 * @param {string} currentLanguage
 * @param {object} options
 * @param {number} [options.limit]
 * @param {string} [options.lastKeyId]
 * @param {number} [options.lastKeyCreatedAt]
 * @returns
 */
const getMyOrder = (jwt, currentLanguage, options = {}) => {
  let queryString = `?limit=${options.limit || DEFAULT_QUERY_MY_ORDERS_LIMIT}`;
  if (options.lastKeyId && options.lastKeyCreatedAt) {
    queryString += `&lastKeyId=${options.lastKeyId}&lastKeyCreatedAt=${options.lastKeyCreatedAt}`;
  }

  return axios.get(`/api/store/user/orders${queryString}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Accept-Language': currentLanguage,
    },
  });
};
const getMyOrderDetails = (orderId, jwt, currentLanguage) => {
  return axios.get(`/api/store/user/orders/${orderId}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Accept-Language': currentLanguage,
    },
  });
};

// Marquee
const marquee = (currentLanguage, marqueeType, productId, eventName) => {
  let path = `system/marquees?marqueeType=${marqueeType}`;
  if (productId) path += `&productId=${productId}`;
  if (eventName) path += `&eventName=${eventName}`;

  return axios.get(`/api/${path}`, {
    headers: {
      'Accept-Language': currentLanguage,
    },
  });
};

const banners = (currentLanguage) => {
  return axios.get('api/store/banners', {
    headers: {
      'Accept-Language': currentLanguage,
    },
  });
};

export {
  basicLogin,
  signUp,
  sendVerification,
  resendVerification,
  sendVerificationForgetPSW,
  checkVerificationForgetPSW,
  updatePassword,
  redeemTicket,
  getTicketInfo,
  bindAccount,
  checkTicketState,
  updateUserInfo,
  userChangeVerify,
  userResetPassword,
  getCodeToVerifyEmail,
  getUserInfo,
  updateEmail,
  googleAuth,
  facebookAuth,
  appleAuth,
  discordAuth,
  sendGift,
  resetPSWSendVerCode,
  resetPSWVerCodeCheck,
  resetPSW,
  authenticateOAuth,
  getPresignedUrl,
  removeAvatarCache,
  removeAvatar,
  deleteUser,
  getMyOrder,
  getMyOrderDetails,
  marquee,
  banners,
};
