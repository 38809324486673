<template>
  <aside>
    <h1>{{ $t('legal.key001') }}</h1>
    <nav>
      <ul>
        <li>
          <router-link :to="`/${currentLanguage}/legal/privacy`" :class="{ active: isActiveLegalPrivacy }">
            {{ $t('legal.key004') }}
          </router-link>
        </li>
        <li>
          <a :href="cookiesUrl[currentLanguage] || cookiesUrl.default" target="_blank">
            {{ $t('legal.key003') }}
          </a>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/legal/user-policy`" :class="{ active: isActiveLegalUserPolicy }">
            {{ $t('legal.key002') }}
          </router-link>
        </li>
        <li>
          <a :href="productSecurityUrl[currentLanguage] || cookiesUrl.default" target="_blank">
            {{ $t('legal.key005') }}
          </a>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/legal/SAR-policy`" :class="{ active: isActiveLegalSARPolicy }"> {{ $t('common.footer_key009') }} </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  name: 'NavAside',
  data() {
    return {
      cookiesUrl: {
        en: 'https://www.htc.com/us/terms/cookies/',
        zh_tw: 'https://www.htc.com/tw/terms/cookies/',
        ja: 'https://www.htc.com/jp/terms/cookies/',
        default: 'https://www.htc.com/us/terms/cookies/',
      },
      productSecurityUrl: {
        en: 'https://www.htc.com/us/terms/product-security/',
        zh_tw: 'https://www.htc.com/tw/terms/product-security/',
        ja: 'https://www.htc.com/jp/terms/product-security/',
        default: 'https://www.htc.com/us/terms/product-security/',
      },
    };
  },
  computed: {
    isActiveLegalPrivacy() {
      return this.$route.query.legal === 'privacy' || this.$route.path.startsWith(`/${this.currentLanguage}/legal/privacy`);
    },
    isActiveLegalUserPolicy() {
      return this.$route.query.legal === 'user-policy' || this.$route.path.startsWith(`/${this.currentLanguage}/legal/user-policy`);
    },
    isActiveLegalSARPolicy() {
      return this.$route.query.legal === 'SAR-policy' || this.$route.path.startsWith(`/${this.currentLanguage}/legal/SAR-policy`);
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
